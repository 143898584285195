import NavDropdown from 'react-bootstrap/NavDropdown';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { Cart } from 'react-bootstrap-icons';
import { DropdownHeader, Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import { AuthorisedGetDataService } from '../../Reducer/DataService/GetDataService';

const MyCartComponent = (props) => {
    const { theam } = props
    const [isLoading, setIsLoading] = useState(true);
    const [myCart, setMyCart] = useState([]);

    useEffect(() => {
        getMyCartDetails()
    }, []);

    const getMyCartDetails = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetMyCart");
        setMyCart(response);
        setIsLoading(false);
    }
   
    const getTitle = () => {
        return (
            <Button variant={theam}>
                <Cart />
                <Badge bg="success">{myCart && myCart[0]?.cartDetails ? myCart[0]?.cartDetails.length : 0}</Badge>
            </Button>
        )
    }
    const getOption = (cartDetail) => {
        return (<NavDropdown.Item href="#action3"><Image src={"data:image/png;base64,"+ cartDetail.imageData} className="rounded-circle" width="30" height="30" /> {cartDetail.productTitle} </NavDropdown.Item>)
    }
    return (
        <NavDropdown title={getTitle()} cssClass='e-caret-hide' >
            {myCart && myCart[0]?.cartDetails ? 
                <>
                    <DropdownHeader href="#action3">You have added {myCart[0]?.cartDetails.length} items</DropdownHeader>
                    <NavDropdown.Divider />
                    {myCart[0]?.cartDetails.map((row) => getOption(row))}
                    <NavDropdown.Divider />
                    <DropdownHeader>
                        <Button className='btn btn-primary'> Buy Now</Button>&nbsp;
                        <a href='/home/CartDetails' className='btn btn-secondary text-end'> See cart details</a>
                    </DropdownHeader>
                </>
                : ""}
        </NavDropdown>
    )
}

export default MyCartComponent;