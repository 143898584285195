import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

import CategoryBreadCrumb from '../../Component/MasterComponent/Category/CategoryBreadCrumb';
import CategoryViewer from '../../Component/MasterComponent/Category/CategoryViewer';
import CategoryEditor from '../../Component/MasterComponent/Category/CategoryEditor';
import { AuthorisedGetDataService } from '../../Reducer/DataService/GetDataService';
import { categoryDefaultData } from '../../Constants/DefaultDataConstant';

const CategoryPage = () => {

    const [modelShow, setModelShow] = useState(false);
    const [actionType, setActionType] = useState('NEW');
    const [productGroups, setProductGroups] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState(categoryDefaultData);

    useEffect(() => {
        getCategories();
        getProductGroups();
    }, []);

    const getProductGroups = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetAllProductGroup");
        setProductGroups(response);
        setIsLoading(false);
    }

    const getCategories = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetAllCategories");
        setCategories(response);
        setIsLoading(false);
    }


    const newRecord = () => {
        setActionType('NEW');
        setModelShow(true);
        setFormData(categoryDefaultData);
    }

    const onHide = () => {
        setModelShow(false);
    }
    const onEditRecord = (category) => {
        setFormData({
            categoryId: category.categoryId,
            categoryName: category.categoryName,
            description: category.description,
            status: category.status
        });
        setActionType("UPDATE");
        setModelShow(true);
    }
    const handleSubmit = async (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
            await getCategories();
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <CategoryBreadCrumb />
                {GetAlert()}
                <CategoryViewer onNew={newRecord} onEdit={onEditRecord} categories={categories} isLoading={isLoading} />
                <CategoryEditor show={modelShow} onHide={onHide} actionType={actionType} formData={formData} handleSubmit={handleSubmit} productGroups={productGroups} />
            </Col>
        </Row>
    )
}

export default CategoryPage;