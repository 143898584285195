const monthData = [
    { "short": "JAN", "full": "January" },
    { "short": "FEB", "full": "January" },
    { "short": "MAR", "full": "January" },
    { "short": "APR", "full": "January" },
    { "short": "MAY", "full": "January" },
    { "short": "JUN", "full": "January" },
    { "short": "JUL", "full": "January" },
    { "short": "AUG", "full": "January" },
    { "short": "SEP", "full": "January" },
    { "short": "OCT", "full": "January" },
    { "short": "NOV", "full": "January" },
    { "short": "DEC", "full": "January" }
]
const getShortMonth = (key) => {
    return (monthData[key].short);
}

export const DefaultDateFormat = (stringDate) => {
    const date = new Date(stringDate);
    return (`${date.getDate()} - ${getShortMonth(date.getMonth())} - ${date.getFullYear()}`)
}