import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import { PlusSquare } from "react-bootstrap-icons";

import { AuthorisedPostDataService } from "../../Reducer/DataService/PostDataService";
import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";

export const ProductVarientEditor = (props) => {
    const { show, onHide, actionType, handleSubmit, formData } = props;
    const [isloading, setIsloading] = useState(false);
    const [productVarient, setProductVarient] = useState(formData);
    const [varients, setVarients] = useState([]);

    useEffect(() => {
        getAllActiveVarients();
    }, []);

    const getAllActiveVarients = async () => {
        var response = await AuthorisedGetDataService("GetAllActiveVarients");
        setVarients(response);
    }

    const validateForm = () => {
        if (productVarient.varientId && productVarient.varientValue) {
            return true;
        }
        return false;
    }
    const saveRecord = async (e) => {
        debugger
        setIsloading(true);
        if (validateForm()) {
            const response = await AuthorisedPostDataService(productVarient, "SaveProductVarient");
            props.handleSubmit(response);
            handleSubmit(response);
        }
        setIsloading(false);
    }

    const handleSelectChange = (e) => {
        productVarient.varientId = e.target.value;
    }

    const onDescriptionChange = (e) => {
        productVarient.varientValue = e.target.value;
    }
    const getFormTitle = () => {
        if (actionType && actionType === 'UPDATE') {
            return 'Update Category';
        }
        else {
            return 'Add New Varient';
        }
    }
    const getOption = (row) => {
        return (
            <option value={row.varientId}>{row.varientName}</option>
        )
    }

    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>
                    {getFormTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="CategoryName">
                            <Form.Label>Option </Form.Label>
                            <Form.Select onChange={handleSelectChange} defaultValue={formData.varientId}>
                                <option>Select</option>
                                {varients ? varients.map((row) => getOption(row)) : ""}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="CategoryName">
                            <Form.Label>Value </Form.Label>
                            <Form.Control as="textarea" rows={2} placeholder="Description" defaultValue={formData.varientValue} onChange={onDescriptionChange} />
                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                {isloading ? <Button className='btn btn-primary' > <Spinner animation='grow' /> Saving ...</Button> :
                    <Button className='btn btn-primary' onClick={saveRecord} > Save</Button>}
            </Modal.Footer>
        </Modal>
    )
}