import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Carousel, Col, Container, Image, Navbar, Row, Spinner } from "react-bootstrap";
import { CurrencyRupee, Star, StarFill, StarHalf } from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";

import { ProductImages } from "../Component/ProductDetails/ProductImages";
import { GetDataService } from "../Reducer/DataService/GetDataService";
import { AddToCartModal } from "../Component/Cart/AddToCartModal";
import { cartDefaultData } from "../Constants/DefaultDataConstant";
import LoginPage from "./LoginPage";
import Register from "./Register";

const ProductDetails = (props) => {
    const { theam } = props;
    const [searchParams] = useSearchParams();
    const [product, setProduct] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [productId, setProductId] = useState(searchParams.get("productId"));
    const [isLoading, setIsLoading] = useState(false);
    const [modelShow, setModelShow] = useState(false);
    const [cartFormData, setCartFormData] = useState(cartDefaultData);
    const [loginShow, setLoginShow] = useState(false);
    const [signupShow, setSignupShow] = useState(false);

    useEffect(() => {
        getProductById(productId);
        getProductImages(productId);
    }, []);

    const getProductById = async (productId) => {
        setIsLoading(true);
        if (productId) {
            var response = await GetDataService("GetProductById/" + productId);
            setProduct(response);
        }
        setIsLoading(false);
    }

    const getProductImages = async (productId) => {
        setIsLoading(true);
        if (productId) {
            var response = await GetDataService("GetProductImagesById/" + productId);
            setProductImages(response);
        }
        setIsLoading(false);
    }

    const onAddCart = (product) => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser) {
            cartFormData.productId = product.productId;
            cartFormData.quantity = 1;
            cartFormData.price = product.price;
            setModelShow(true);
        }
        else {
            showLogin()
        }
    }

    const onHide = () => {
        setModelShow(false);
    }
const showLogin = () => {        
        setLoginShow(true);
        setSignupShow(false);        
    }
    const onLoginHide=()=>{
        setLoginShow(false);
    }

    const onSignup=()=>{
        setSignupShow(true);
        setLoginShow(false);
    }

    const onSignupHide=()=>{
        setSignupShow(false);
    }
    const handleSubmit=(response)=>{
        if (response == "200") {
            setModelShow(false);
        }
    }

    return (
        <Container style={{marginTop: 150,marginBottom:70}}>
            {isLoading ?
                <Row className='pe-0' style={{ flex: 1,  justifyContent: 'center', alignContent: 'center' }} >
                    <Col md={2}>
                        <Spinner animation="grow" variant={theam} size="sm" style={{ flex: 1, alignSelf: 'center' }} />
                        <Spinner animation="grow" variant={theam} style={{ flex: 1, alignSelf: 'center' }} />
                    </Col>
                </Row>
                : product ?
                    <Card>
                        <CardHeader>
                            <CardTitle>{product.productTitle}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row className='pe-0' >
                                <Col md={5}>
                                    <ProductImages productImages={productImages} />
                                </Col>
                                <Col md={7}>
                                    <CardText><b>
                                        {product.productTitle}</b>
                                    </CardText>
                                    {<CardText dangerouslySetInnerHTML={{ __html: product.description }} ></CardText>}
                                    <CardText>
                                        <b>{product.tagTitle}</b>
                                        <p>Brand: {product.vendorName}</p>
                                        <p><StarFill /><StarFill /><StarFill /><StarHalf /><Star /></p>
                                        <b>Price: </b>{product.price}<CurrencyRupee />/ {product ? product.measuringUnitName : ""}
                                    </CardText>
                                    <CardText>
                                        <Button onClick={()=> onAddCart(product)} > Add to Cart</Button>&nbsp;
                                        <Button className="float-end" > Buy now</Button>
                                    </CardText>
                                </Col>
                            </Row>
                        </CardBody>
                        <AddToCartModal show={modelShow} onHide={onHide} formData={cartFormData} handleSubmit={handleSubmit} />
                        <LoginPage show={loginShow} onHide={onLoginHide} onSignup={onSignup} theam={theam} />
                        <Register show={signupShow} onHide={onSignupHide} onLogin={showLogin} theam={theam} />
                    </Card>
                     : ""}
        </Container>
    )
}
export default ProductDetails;