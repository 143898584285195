import { useEffect, useState } from 'react';
import { PencilSquare, PlusSquare, Trash } from 'react-bootstrap-icons';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { DefaultDateFormat } from '../../../Utility';

const ProductGroupViewer = (props) => {
    const { onNew, onEdit, isLoading, productGroups } = props;

    const onDeleteHandle = (productGroupId) => {

    }
    const getRow = (id, productGroup) => {
        return (
            <tr key={id}>
                <td>
                    {productGroup.productGroupId}
                </td>
                <td>
                    {productGroup.groupName}
                </td>
                <td>
                    {productGroup.description}
                </td>
                <td>
                    {productGroup.status == 1 ? "Active" : "InActive"}
                </td>
                <td>
                    {DefaultDateFormat(productGroup.createdDate)}
                </td>
                <td>
                    <Button className='ps-2' variant="primary" onClick={()=>onEdit(productGroup)}><PencilSquare /></Button> &nbsp;
                </td>
            </tr>
        )
    }

    return (isLoading ? <h3>loading...</h3> : (
        <Card>
            <Card.Header>
                <Row >
                    <Col>
                        <Card.Title>Product Group Information
                        <Button className='float-end' variant="primary" onClick={onNew}><PlusSquare /> New</Button></Card.Title>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    Id
                                </th>
                                <th>
                                    Group Name
                                </th>
                                <th>
                                    Description
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Created Date
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {productGroups ? productGroups.map((rowData, rowKey) => getRow(rowKey, rowData)) : "No Record found"}
                        </tbody>
                    </Table>
                </Col>
            </Card.Body>
        </Card>
    )
    );
}

export default ProductGroupViewer;