import { BaseUrl } from "../../Constants/ApiConstants";

export const GetDataService = async (method) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    const response = await fetch(BaseUrl + method, requestOptions);
    return response ? await response.json() : null;
}

export const AuthorisedGetDataService = async (method) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${loggedInUser.token}`
        }
    };
    const response = await fetch(BaseUrl + method, requestOptions);
    return response.ok ? await response.json() : null;
}

