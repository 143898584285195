import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Spinner } from "react-bootstrap";

import { MyCartViewer } from "../Component/Cart/MyCartViewer";
import { cartDefaultData } from "../Constants/DefaultDataConstant";
import { AddToCartModal } from "../Component/Cart/AddToCartModal";
import { AuthorisedGetDataService } from "../Reducer/DataService/GetDataService";
import { DeleteAlert } from "../Component/Common/DeleteAlert";
import { AuthorisedDeleteDataService } from "../Reducer/DataService/PostDataService";
import { Payment } from "../Component/Payment/Payment";

export const MyCartDetails = (props) => {
    const { theam } = props
    const [actionType, setActionType] = useState('NEW');
    const [isLoading, setIsLoading] = useState(true);
    const [modelShow, setModelShow] = useState(false);
    const [paymentShow, setPaymentShow] = useState(false);
    const [deleteAlertShow, setDeleteAlertShow] = useState(false);
    const [cartFormData, setCartFormData] = useState(cartDefaultData);
    const [myCarts, setMyCarts] = useState([]);
    const [deleteRecordId, setDeleteRecordId] = useState(0);
const[totalAmount,setTotalAmount]=useState(0);
    useEffect(() => {
        getMyCartDetails();
    }, []);

    const getMyCartDetails = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetMyCart");
        debugger
        setMyCarts(response);
        setIsLoading(false);
    }
    const onEdit = (cartDetail) => {
        cartFormData.cartId = cartDetail.cartId;
        cartFormData.cartDetailId = cartDetail.cartDetailId;
        cartFormData.productId = cartDetail.productId;
        cartFormData.quantity = cartDetail.quantity;
        cartFormData.price = cartDetail.price;
        setActionType("UPDATE");
        setModelShow(true);
    }
    const onHide = () => {
        setModelShow(false);
    }
    const handleSubmit = (response) => {
        if (response == "200") {
            setModelShow(false);
            getMyCartDetails();
        }
    }
    const onDeleteCancel = () => {
        setDeleteAlertShow(false);
    }
    const onDeleteHandle = (cartDetailId) => {
        setDeleteAlertShow(true);
        setDeleteRecordId(cartDetailId);
    }

    const onDeleteConfirm = async () => {
        const response = await AuthorisedDeleteDataService(`DeleteFromCart/${deleteRecordId}`);
        if (response == "200") {
            setDeleteAlertShow(false);
            getMyCartDetails();
        }
    }
    const showPayment = (totalAmount) => {
        setTotalAmount(totalAmount);
        setPaymentShow(true);
    }

    const onPaymentHide = () => {
        setPaymentShow(false);
    }

    return (
        <Container style={{ marginTop: 150, marginBottom: 70 }}>
            {isLoading ?
                <Row className='pe-0' style={{ flex: 1, marginTop: 340, justifyContent: 'center', alignContent: 'center' }} >
                    <Col md={2}>
                        <Spinner animation="grow" variant={theam} size="sm" style={{ flex: 1, alignSelf: 'center' }} />
                        <Spinner animation="grow" variant={theam} style={{ flex: 1, alignSelf: 'center' }} />
                    </Col>
                </Row>
                : 
            <Card>
                <CardHeader>
                    <CardTitle>Cart Details</CardTitle>
                </CardHeader>
                <CardBody>
                    <MyCartViewer myCarts={myCarts} onEdit={onEdit} onDeleteHandle={onDeleteHandle} buyNow={showPayment} />
                    <AddToCartModal actionType={actionType} show={modelShow} onHide={onHide} formData={cartFormData} handleSubmit={handleSubmit} />
                    <DeleteAlert show={deleteAlertShow} onHide={onDeleteCancel} onConfirm={onDeleteConfirm}/>
                </CardBody>
            </Card>}
            <Payment show={paymentShow} onHide={onPaymentHide} totalAmount={totalAmount} />
        </Container>
    )
}