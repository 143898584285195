import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Card, CardBody, Modal, Navbar, Spinner } from "react-bootstrap"
import { Upload } from "react-bootstrap-icons"
import { PostUploadProductImages } from "../../Reducer/DataService/PostDataService";


export const UploadProductImage=(props)=>{
    const { show, onHide, handleSubmit,productId } = props;
    const [files, SetFiles] = useState([]);
    const [isloading, setIsloading] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            SetFiles(acceptedFiles);
        },
    });
    const validateForm = () => {
        if (files && files.length > 0 && productId) {
            return true;
        }
        return false;
    }
    const saveRecord = async (e) => {
        setIsloading(true);
        if (validateForm()) {
            const response = await PostUploadProductImages(files, `UploadImages/${productId}`);            
            handleSubmit(response);
        }
        setIsloading(false);
    }

    const getUploadedFileList = (uploadedFiles) => {
        return (
            <Navbar>
                {uploadedFiles && uploadedFiles.length > 0 ? uploadedFiles.map((file) => (
                    <Card className='ms-3' key={file.name}>
                        <Card.Img height={50} width={50} src={URL.createObjectURL(file)} />
                    </Card >
                )) : ""}
            </Navbar>
        )
    }
    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>
                    Upload product images
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div {...getRootProps()}>
                    {getUploadedFileList(files)}
                    <Card>
                        <CardBody className='text-center align-middle' style={{ minHeight: 100 }}>
                            <input className='form-control' {...getInputProps()} />
                            Drag and drop images here or <Button ><Upload /> click to browse. </Button>
                        </CardBody>
                    </Card>
                </div>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                {isloading ? <Button className='btn btn-primary' > <Spinner animation='grow' /> Saving ...</Button>:
                <Button className='btn btn-primary' onClick={saveRecord} > Save</Button>}
            </Modal.Footer>
        </Modal>
    )
}