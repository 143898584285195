import { useState } from "react";
import { Pagination } from "react-bootstrap"

export const PagingComponent = (props) => {
    const { totalRecords, pageSize, currentPage, onPageClickHandle } = props;
    const [requestedPage, setRequestedPage] = useState(currentPage);
    const [totalPageCount, setTotalPageCount] = useState(Math.ceil(totalRecords / pageSize));

    const onFirstClick = () => {
        setRequestedPage(1);
        onPageClickHandle(1);
    }

    const onLastClick = () => {
        debugger
        setRequestedPage(totalPageCount);
        onPageClickHandle(totalPageCount);
    }

    const onPrevClick = () => {
        const activePage = requestedPage <= 1 ? requestedPage : requestedPage - 1;
        setRequestedPage(activePage);
        onPageClickHandle(activePage);
    }

    const onNextClick = () => {
        debugger
        const activePage = requestedPage >= totalPageCount ? requestedPage : requestedPage + 1;
        setRequestedPage(activePage);
        onPageClickHandle(activePage);
    }

    const onPageClick = (pagecount) => {
        debugger
        setRequestedPage(pagecount);
        onPageClickHandle(pagecount);
    }
    const getItems = () => {
        const rows = [];
        for (let i = 1; i <= totalPageCount; i++) {
            rows.push(<Pagination.Item onClick={() => onPageClick(i)} >{i}</Pagination.Item>);
        }
        return rows;
    }
    return (
        <Pagination>
            <Pagination.First onClick={onFirstClick} />
            <Pagination.Prev onClick={onPrevClick} />
            {getItems()}
            <Pagination.Next onClick={onNextClick} />
            <Pagination.Last onClick={onLastClick} />
        </Pagination>
    )
}