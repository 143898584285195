import { useState } from 'react';
import { Navbar, Nav, Button, NavItem } from 'react-bootstrap';
import { Navigate } from 'react-router';

import { useAuth } from '../Auth/AuthProvider';
import SearchComponent from './SearchComponent';
import LogoComponent from './LogoComponent';
import NotificationsComponent from './NotificationsComponent';
import MyCartComponent from './MyCartComponent';
import ProfileComponent from './ProfileComponent';
import LoginPage from '../../Pages/LoginPage';
import Register from '../../Pages/Register';
import ForgetPassword from '../../Pages/ForgetPassword';
import { UserAccountComponent } from '../Common/UserAccountComponent';

const HeaderComponent = (props) => {
  const auth = useAuth();
  const { theam } = props;
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("loggedInUser")) || null);
  const [loginShow, setLoginShow] = useState(false);

  const onLogout = () => {
    auth.logoutAction();
    setUser(null);
  }
  const onLogin=()=>{
    setLoginShow(true);
  }
  const onHide=()=>{
    setLoginShow(false);
  }
  const loginSuccessFull = () => {
    setUser(JSON.parse(localStorage.getItem("loggedInUser")));
    setLoginShow(false);
  }

  return (
    <>
      <Navbar fixed="top" expand="lg" bg={theam} variant={theam} className="bg-body-light pe-0 ps-2">
        <Navbar.Brand href="/" className="logo d-flex align-items-center ps-1 pe-0">
          <LogoComponent theam={theam} />
        </Navbar.Brand>
        <Navbar.Brand href="/" className="logo d-flex align-items-center ps-1 pe-0">
          <div variant={theam}>Home</div>
        </Navbar.Brand>
        <Navbar.Brand href="/" className="logo d-flex align-items-center ps-1 pe-0">
          <div variant={theam}>About</div>
        </Navbar.Brand>
        <Navbar.Brand href="/" className="logo d-flex align-items-center ps-1 pe-0">
          <div variant={theam}>Contact Us</div>
        </Navbar.Brand>
        <SearchComponent />
        {user ?
          <Nav className="justify-content-end flex-grow-1 pe-5">
            <MyCartComponent className="pe-0" user={user} theam={theam} />
            <ProfileComponent user={user} auth={auth} onLogout={onLogout} theam={theam} />
          </Nav> :
          <Nav className="justify-content-end flex-grow-1 pe-5">
            <NavItem className='float-end'><Button className='Primary' onClick={onLogin}>Login</Button></NavItem>
          </Nav>}
      </Navbar>
      <UserAccountComponent theam={theam} onLogin={onLogin} onHide={onHide} loginShow={loginShow} loginSuccessFull={loginSuccessFull} />
    </>
  )
}

export default HeaderComponent;