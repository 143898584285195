import { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";

import { AuthorisedGetDataService } from "../Reducer/DataService/GetDataService";
import { CustomerListBreadCrumb } from "../Component/Customers/CustomerListBreadCrumb";
import { CustomerViewer } from "../Component/Customers/CustomerViewer";
import { customerDefaultData } from "../Constants/DefaultDataConstant";
import { CustomerEditor } from "../Component/Customers/CustomerEditor";

export const Customers = () => {
    const [modelShow, setModelShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [customerData, setCustomerData] = useState(customerDefaultData);
    const [actionType, setActionType] = useState('NEW');
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        getCustomers();
    }, []);

    const getCustomers = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetAllCustomers");
        setCustomers(response);
        setIsLoading(false);
    }

    const newRecord = () => {
        setActionType('NEW');
        setModelShow(true);
        setCustomerData(customerDefaultData);
    }

    const onHide = () => {
        setModelShow(false);
    }
    const onEditRecord = (customer) => {
        setCustomerData({
            customerId: customer.customerId,
            firstName: customer.firstName,
            lastName: customer.lastName,
            emailId: customer.emailId,
            phoneNumber: customer.phoneNumber,
            alternatePhoneNumber: customer.alternatePhoneNumber,
            status: customer.status
        });
        setActionType("UPDATE");
        setModelShow(true);
    }
    const handleSubmit = async (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
            await getCustomers();
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <CustomerListBreadCrumb />
                {GetAlert()}
                <CustomerViewer customers={customers} isLoading={isLoading} onEdit={onEditRecord} onNew={newRecord} />
                <CustomerEditor show={modelShow} onHide={onHide} actionType={actionType} formData={customerData} handleSubmit={handleSubmit} />
            </Col>
        </Row>
    )
}