import { Carousel, Image } from "react-bootstrap"

import Banner1 from '../../Images/Banner1.jpg';
import Banner2 from '../../Images/Banner2.jpg';
import Banner3 from '../../Images/Banner3.jpg';
import Banner4 from '../../Images/Banner4.jpg';
import Banner5 from '../../Images/Banner5.jpg';

export const HomeBanner = (props) => {
    const interval=5000;

    return (
        <Carousel fade>          
            <Carousel.Item interval={interval}>
                <Image src={Banner1} />
            </Carousel.Item>
            <Carousel.Item interval={interval}>
                <Image src={Banner2} />
            </Carousel.Item>
            <Carousel.Item interval={interval}>
                <Image src={Banner3} />
            </Carousel.Item>
            <Carousel.Item interval={interval}>
                <Image src={Banner4} />
            </Carousel.Item>
            <Carousel.Item interval={interval}>
                <Image src={Banner5} />
            </Carousel.Item>            
        </Carousel>
    )
}