import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeLayout from './Router/HomeLayout';

import CategoryPage from './Pages/MasterPages/CategoryPage';
import SubCategoryPage from './Pages/MasterPages/SubCategoryPage';
import MeasuringUnitPage from './Pages/MasterPages/MeasuringUnitPage';
import Products from './Pages/Products';
import ProductDetails from './Pages/ProductDetails';
import AuthProvider from './Component/Auth/AuthProvider';
import PrivateRoute from './Component/Auth/PrivateRoute';
import TagPage from './Pages/MasterPages/TagPage';
import VarientPage from './Pages/MasterPages/VarientPage';
import VendorPage from './Pages/MasterPages/VendorPage';
import { Customers } from './Pages/Customers';
import { ProductImages } from './Pages/ProductImages';
import { ProductVarients } from './Pages/ProductVarients';
import { ProductStocks } from './Pages/ProductStocks';

import { HomePage } from './Pages/HomePage';
import ProductGroupPage from './Pages/MasterPages/ProductGroupPage';
import { MyProfilePage } from './Pages/MyProfilePage';
import { MyCartDetails } from './Pages/MyCartDetails';
import { MyOrderPage } from './Pages/MyOrderPage';
import { ProductGrid } from './Pages/ProductGrid';
import { MaterialReturnPolicy } from './Pages/MaterialReturnPolicy';
import { BuyProduct } from './Pages/BuyProduct';
import {ChangePassword} from './Pages/ChangePassword';
import { Orders } from './Pages/Orders';
import { Refund } from './Pages/Refund';


function App() {
    const [theam, setTheam] = useState("light");
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }
    return (
        <div>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    Some text as placeholder. In real life you can have the elements you
                    have chosen. Like, text, images, lists, etc.
                </Offcanvas.Body>
            </Offcanvas>
            <Router>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<HomeLayout theam={theam} />} >
                            <Route path="/" element={<HomePage theam={theam} />} />
                        </Route>
                        <Route path="/home" element={<HomeLayout theam={theam} />} >
                            <Route path="/home" element={<HomePage theam={theam} />} />
                            <Route path='ProductDetails' element={<ProductDetails theam={theam} />} />
                            <Route path='ProfilePage' element={<MyProfilePage  theam={theam} />} />
                            <Route path='CartDetails' element={<MyCartDetails  theam={theam} />} />
                            <Route path='OrderPage' element={<MyOrderPage  theam={theam} />} />
                            <Route path='ProductGrid' element={<ProductGrid theam={theam} />} />
                            <Route path='MaterialReturnPolicy' element={<MaterialReturnPolicy theam={theam} />} />
                            <Route path='BuyProduct' element={<BuyProduct theam={theam} />} />
                            <Route path='ChangePassword' element={<ChangePassword theam={theam} />} />
                            <Route path='Refund' element={<Refund theam={theam} />} />
                           
                        </Route>
                        <Route path="/admin" element={<PrivateRoute theam={theam} />}>
                            <Route path="productGroup" element={<ProductGroupPage />} />
                            <Route path="category" element={<CategoryPage />} />
                            <Route path="subCategory" element={<SubCategoryPage />} />
                            <Route path="measuringUnit" element={<MeasuringUnitPage />} />
                            <Route path="tag" element={<TagPage />} />
                            <Route path="vendor" element={<VendorPage />} />
                            <Route path="varient" element={<VarientPage />} />
                            <Route path="products" element={<Products />} />
                            <Route path="productImages" element={<ProductImages />} />
                            <Route path="productVarients" element={<ProductVarients />} />
                            <Route path="ProductStocks" element={<ProductStocks />} />
                            <Route path="Customers" element={<Customers />} />
                            <Route path="Orders" element={<Orders />} />
                            <Route path="Refund" element={<Refund />} />
                        </Route>
                    </Routes>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;
