
export const DefaultPageSize=15;
export const productGroupDefaultData = {
    productGroupId: "0",
    groupName: "",
    description: "",
    status: ""
}

export const categoryDefaultData = {
    categoryId: "0",
    productGroupId: "0",
    categoryName: "",
    description: "",
    status: ""
}

export const subCategoryDefaultData = {
    subCategoryId: "0",
    category: "",
    subCategoryName: "",
    description: "",
    status: ""
}

export const measuringUnitDefaultData = {
    measuringUnitId: "0",
    unitName: "",
    description: "",
    status: ""
}

export const tagDefaultData = {
    tagId: "0",
    tagTitle: "",
    description: "",
    status: ""
}

export const varientDefaultData = {
    varientId: "0",
    varientName: "",
    description: "",
    status: ""
}

export const vendorDefaultData = {
    vendorId: "0",
    vendorName: "",
    description: "",
    gstNumber: "",
    address: "",
    status: ""
}

export const productDefaultGeneralData = {
    productId: "0",
    productCode: "",
    productTitle: "",
    category: "",
    subCategory: "",
    tag: "",
    vendor: "",
    measuringUnit: "",
    price: 0,
    inStockQuantity: 0,
    description: "",
    status: "0"
}

export const productImageDefaultData = {
    productId: "0",
    files: []
}

export const productVarientDefaultData = {
    productVarientId: "0",
    productId: "",
    varientId: "",
    varientValue: ""
}
export const productStockDefaultData = {
    productStockId: "0",
    productId: "",
    restockQuantity: "",
    restockPrice: ""
}
export const varientOptionDefaultData = {
    productId: "0",
    optionsData: [{
        key: "1",
        varientId: "0",
        value: ""
    },
    {
        key: "2",
        varientId: "0",
        value: ""
    }]
};

export const customerDefaultData = {
    customerId: 0,
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
}

export const cartDefaultData = {
    cartId: 0,
    cartDetailId:0,
    customerId: 0,
    productId: 0,
    quantity: 0,
    price: 0.0
}

export const shippingAddressDefaultData = {
    userShippingAddressId: 0,
    userId: '',
    contactNumber: '',
    contactPersonName: '',
    addressName: '',
    isDefaultAddress: false,
    shippingAddress: '',
    city: 'Faridabad',
    country: 'India',
    postalCode: ''
}
export const orderDefaultData = {
    orderId: '',
    shippingAddressId: '',
    paymentType: '',
    orderStatus: '',
    email:''
}
