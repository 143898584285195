import { PostDataService } from "./PostDataService";

export const LoginService = async (data) => {    
   return await PostDataService(data,"login");
}

export const SignupService = async (data) => {  
    return await PostDataService(data,"register"); 
}

export const ForgetService = async (data) => {  
    return await PostDataService(data,"forgetpassword"); 
}
