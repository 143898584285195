import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

import { productGroupDefaultData } from '../../Constants/DefaultDataConstant';
import { AuthorisedGetDataService } from '../../Reducer/DataService/GetDataService';
import ProductGroupCrumb from '../../Component/MasterComponent/ProductGroup/ProductGroupCrumb';
import ProductGroupViewer from '../../Component/MasterComponent/ProductGroup/ProductGroupViewer';
import ProductGroupEditor from '../../Component/MasterComponent/ProductGroup/ProductGroupEditor';

const ProductGroupPage = () => {

    const [modelShow, setModelShow] = useState(false);
    const [actionType, setActionType] = useState('NEW');
    const [productGroups, setProductGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState(productGroupDefaultData);

    useEffect(() => {
        getProductGroups();
    }, []);

    const getProductGroups = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetAllProductGroup");
        setProductGroups(response);
        setIsLoading(false);
    }


    const newRecord = () => {
        setActionType('NEW');
        setModelShow(true);
        setFormData(productGroupDefaultData);
    }

    const onHide = () => {
        setModelShow(false);
    }
    const onEditRecord = (productGroup) => {
        setFormData({
            productGroupId: productGroup.productGroupId,
            groupName: productGroup.groupName,
            description: productGroup.description,
            status: productGroup.status
        });
        setActionType("UPDATE");
        setModelShow(true);
    }
    const handleSubmit = async (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
            await getProductGroups();
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <ProductGroupCrumb />
                {GetAlert()}
                <ProductGroupViewer onNew={newRecord} onEdit={onEditRecord} productGroups={productGroups} isLoading={isLoading} />
                <ProductGroupEditor show={modelShow} onHide={onHide} actionType={actionType} formData={formData} handleSubmit={handleSubmit} />
            </Col>
        </Row>
    )
}

export default ProductGroupPage;