import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { CurrencyRupee, PhoneFill } from "react-bootstrap-icons";
import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";

export const Payment = (props) => {
    const { show, onHide, totalAmount } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [myShippingAddresses, setMyShippingAddresses] = useState([]);

    useEffect(() => {
        getMyShippingAddress()
    }, []);

    const getMyShippingAddress = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetMyShippingAddress");
        debugger
        setMyShippingAddresses(response);
        setIsLoading(false);
    }
    const handlePaymentOption = () => {

    }
    const onPayment = () => {
        setIsLoading(true);
        setIsLoading(false);
    }

    const getRow = (id, myShippingAddress) => {
        return (
            <div key={id} className="mb-3">
                <Form.Check type='radio' id={`radio-${myShippingAddress.userShippingAddressId}`}>
                    <Form.Check.Input type='radio' isValid checked={myShippingAddress.isDefaultAddress} />
                    <Form.Check.Label><b>{myShippingAddress.addressName}</b></Form.Check.Label>
                    <Form.Control.Feedback type="valid">
                       <b>{myShippingAddress.contactPersonName}</b>, {myShippingAddress.shippingAddress} {myShippingAddress.city}, {myShippingAddress.country} {myShippingAddress.postalCode} 
                        <br /><PhoneFill /> {myShippingAddress.contactNumber}
                    </Form.Control.Feedback>
                </Form.Check>
            </div>
        )
    }
    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title className="justify-content-center">
                    <h4 className='float-center'> Complete the payment </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form className="row g-3 needs-validation" novalidate>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Net payment of: <b>{totalAmount}</b><CurrencyRupee /></Form.Label>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Payment Mode </Form.Label>
                                <Form.Select aria-label="Select Payment Option" defaultValue={0} onChange={handlePaymentOption}>
                                    <option>select</option>
                                    <option value={1}>Cash On Delivery</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                        <Row>
                            <Col md={12} className="text-left">
                                <Form>
                                    <p>Shipping Address</p>
                                    {myShippingAddresses ? myShippingAddresses.map((rowData, rowKey) => getRow(rowKey, rowData)) : ""}
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                {isLoading ? <Button className="btn btn-primary" type="submit" >
                    <Spinner animation='grow' /> Loaging...
                </Button> :
                    <Button className="btn btn-primary" type="submit" onClick={onPayment}>
                        Place Order
                    </Button>}
            </Modal.Footer>

        </Modal>
    );
}