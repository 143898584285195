import { Button, Card, Col, Row, Table } from "react-bootstrap"
import { PencilSquare, PlusSquare, Trash } from "react-bootstrap-icons"
import { DefaultDateFormat } from "../../Utility";

export const CustomerViewer = (props) => {
    const { onNew,onEdit, isLoading, customers } = props;

    const getRow = (id, customer) => {
        return (
            <tr key={id}>
                <td>
                    {customer.customerId}
                </td>
                <td>
                    {customer.firstName} {customer.lastName}
                </td>
                <td>
                    {customer.emailId}
                </td>
                <td>
                    {customer.phoneNumber}
                </td>
                <td>
                    {customer.alternatePhoneNumber}
                </td>                
                <td>
                    {DefaultDateFormat(customer.createdDate)}
                </td>
                <td>
                    <Button className='ps-2' variant="primary" onClick={() => onEdit(customer)}><PencilSquare /></Button> 
                </td>
            </tr>
        )
    }
    return (isLoading ? <h3>loading...</h3> : (
        <Card>
            <Card.Header>
                <Row >
                    <Col>
                        <Card.Title>Customers Information
                            <Button className='float-md-end' variant="primary" onClick={onNew}><PlusSquare /> Add New Customer </Button>
                        </Card.Title>
                    </Col>
                    
                </Row>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    Id
                                </th>
                                <th>
                                    Customer Name
                                </th>
                                <th>
                                    Email Id
                                </th>
                                <th>
                                    Phone Number
                                </th>
                                <th>
                                    Alternate Phone Number
                                </th>                               
                                <th>
                                    CreatedDate
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            {customers.map((rowData, rowKey) => getRow(rowKey, rowData))}
                        </tbody>
                    </Table>
                </Col>
            </Card.Body>
        </Card>
    ));
}