import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Table } from "react-bootstrap"
import { PencilSquare, PlusSquare } from "react-bootstrap-icons";

import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";
import { DefaultDateFormat } from "../../Utility";

export const ShippingAddressViewer = (props) => {
    const { theam, onNew,onEdit } = props
    const [isLoading, setIsLoading] = useState(true);
    const [myShippingAddresses, setMyShippingAddresses] = useState([]);

    useEffect(() => {
        getMyShippingAddress()
    }, []);

    const getMyShippingAddress = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetMyShippingAddress");
        debugger
        setMyShippingAddresses(response);
        setIsLoading(false);
    }

    const getRow = (id, myShippingAddress) => {
        return (
            <tr key={id}>
                <td>
                    {myShippingAddress.userShippingAddressId}
                </td>
                <td>
                    {myShippingAddress.addressName}
                </td>
                <td>
                    {myShippingAddress.contactPersonName}
                </td>
                <td>
                    {myShippingAddress.contactNumber}
                </td>
                <td>
                    {myShippingAddress.shippingAddress}
                </td>
                <td>
                    {myShippingAddress.city}
                </td>
                <td>
                    {myShippingAddress.country}
                </td>
                
                <td>
                    {myShippingAddress.postalCode}
                </td>
                <td>
                    {myShippingAddress.isDefaultAddress ? "YES" : "NO"}
                </td>
                <td>
                    {DefaultDateFormat(myShippingAddress.createdDate)}
                </td>
                <td>
                    <Button className='ps-2' variant="primary" onClick={() => onEdit(myShippingAddress)}><PencilSquare /></Button> &nbsp;
                </td>
            </tr>
        )
    }

    return (
        <Card style={{ marginTop: 15 }}>
            <CardHeader>
                <CardTitle>My Shipping Address
                    <a className='btn btn-primary float-end' variant="primary" onClick={onNew}><PlusSquare /></a>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Row className='pe-0' >
                    <Col md={12}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>
                                        Id
                                    </th>
                                    <th>
                                        AddressName
                                    </th>
                                    <th>
                                        Contact Person Name
                                    </th>
                                    <th>
                                        Contact Number
                                    </th>

                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        City
                                    </th>
                                    <th>
                                        Country
                                    </th>
                                    <th>
                                        Postal Code
                                    </th>

                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Created Date
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>

                            </thead>
                            <tbody>
                                {myShippingAddresses?myShippingAddresses.map((rowData, rowKey) => getRow(rowKey, rowData)):""}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}