import { Col, Row } from "react-bootstrap"
import { Outlet } from "react-router"

import HeaderComponent from "../Component/Header/HeaderComponent"
import SidebarComponent from "../Component/Sidebar/SidebarComponent"
import FooterComponent from "../Component/Footer/FooterComponent"


export const AdminLayout=(props)=>{
    const { theam } = props
    return (
        <div fluid >
            <HeaderComponent theam={theam}/>
            <Row className='pe-0' style={{ marginTop: 86, marginBottom: 60 }} >
                <Col md={2} xs={{ order: 'first' }} className='flex-column'>
                    <SidebarComponent theam={theam}/>
                </Col>
                <Col md={10} xs={{ order: 'last' }} className='flex-column pe-3 ps-0'>
                    <Row>
                        <Col md={12}>
                            <Outlet />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <FooterComponent theam={theam} />
        </div>
    )
}