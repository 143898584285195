import { useState } from "react";
import { Alert, Card, CardBody, CardHeader, CardTitle, Col, Container, Image, Row, Spinner } from "react-bootstrap";

import ProfileImage from '../Images/ProfileImage.jpg';
import { ShippingAddressViewer } from "../Component/MyProfile/ShippingAddressViewer";
import { shippingAddressDefaultData } from "../Constants/DefaultDataConstant";
import { ShippingAddressEditor } from "../Component/MyProfile/ShippingAddressEditor";

export const MyProfilePage = (props) => {
    const { theam } = props
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [actionType, setActionType] = useState('NEW');
    const [modelShow, setModelShow] = useState(false);

    const [shippingAddressData, setShippingAddressData] = useState(shippingAddressDefaultData);
    const [user,setUser]=useState(false);
    const [shippingAddresses, setShippingAddresses] = useState([]);

    const newRecord = () => {
        setActionType('NEW');
        setModelShow(true);
        setShippingAddressData(shippingAddressDefaultData);
    }

    const editRecord = (shippingAddress) => {
        setShippingAddressData({
            userShippingAddressId: shippingAddress.userShippingAddressId,
            contactNumber: shippingAddress.contactNumber,
            contactPersonName: shippingAddress.contactPersonName,
            addressName: shippingAddress.addressName,
            isDefaultAddress: shippingAddress.isDefaultAddress,
            shippingAddress: shippingAddress.shippingAddress,
            city: shippingAddress.city,
            country: shippingAddress.country,
            postalCode: shippingAddress.postalCode
        });
        setActionType("UPDATE");
        setModelShow(true);
    }
    const onHide = () => {
        setModelShow(false);
    }

    const handleSubmit = async (result) => {
        if (result == 200) {
            setMessage("Record save successfully.");
            setVariant("success");
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }

    return (
        <Container style={{ marginTop: 150, marginBottom: 70 }}>
            {isLoading ?
                <Row className='pe-0' style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }} >
                    <Col md={2}>
                        <Spinner animation="grow" variant={theam} size="sm" style={{ flex: 1, alignSelf: 'center' }} />
                        <Spinner animation="grow" variant={theam} style={{ flex: 1, alignSelf: 'center' }} />
                    </Col>
                </Row> : <>
                    <Card>
                        <CardHeader>
                            <CardTitle>My Profile</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row className='pe-0' >
                                <Col md={3}>
                                    <Row style={{ marginBottom: 20 }}>
                                        <Col>
                                            <Image src={ProfileImage} alt={user.firstName} className="rounded-circle" width="150" height="150" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6>Lokendra Kumar</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={9}>
                                   
                                            <Row>
                                                <Col md={3}>
                                                    Date of Birth:
                                                </Col>
                                                <Col md={9}></Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col md={3}>
                                                    Email Id:
                                                </Col>
                                                <Col md={9}></Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col md={3}>
                                                    Primary Contact Number:
                                                </Col>
                                                <Col md={9}></Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col md={3}>
                                                    Alternate Contact Number:
                                                </Col>
                                                <Col md={9}></Col>
                                            </Row>
                                            <hr />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {GetAlert()}
                    <ShippingAddressViewer shippingAddresses={shippingAddresses} isLoading={isLoading} onEdit={editRecord} onNew={newRecord} />
                    <ShippingAddressEditor show={modelShow} onHide={onHide} actionType={actionType} formData={shippingAddressData} handleSubmit={handleSubmit} />
                </>}
        </Container>
    )
}