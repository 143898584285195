import { useState } from "react";
import ForgetPassword from "../../Pages/ForgetPassword";
import LoginPage from "../../Pages/LoginPage";
import Register from "../../Pages/Register";

export const UserAccountComponent = (props) => {
    const { theam, onLogin, onHide, loginShow, loginSuccessFull } = props;

    const [signupShow, setSignupShow] = useState(false);
    const [forgetPasswordShow, setForgetPasswordShow] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("loggedInUser")) || null);

    const showLogin = () => {
        onLogin();
        setSignupShow(false);
        setForgetPasswordShow(false);
    }

    const onSignup = () => {
        setSignupShow(true);
        onHide();
    }

    const onSignupHide = () => {
        setSignupShow(false);
    }
    const showForgetPassword = () => {
        setForgetPasswordShow(true);
        onHide();
    }
    const onForgetPassword = () => {
        setForgetPasswordShow(false);
    }

    return (
        <>
            <LoginPage show={loginShow} onHide={onHide} onSignup={onSignup} onForget={showForgetPassword} loginDone={loginSuccessFull} theam={theam} />
            <Register show={signupShow} onHide={onSignupHide} onLogin={showLogin} theam={theam} />
            <ForgetPassword show={forgetPasswordShow} onHide={onForgetPassword} onLogin={showLogin} theam={theam} />
        </>
    )
}