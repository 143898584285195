import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner,Accordion } from "react-bootstrap";
 

export const MaterialReturnPolicy = (props) => {
  return (
    <Container style={{ marginTop: 150, marginBottom: 70 }}>
      <h2>Material Return & Replacement Policy</h2>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header><h5>Return Conditions</h5></Accordion.Header>
            <Accordion.Body>     
              <ol>
                <li> Items where packaging has been tampered with will not be accepted for returns.</li>
 
                <li> Material should be in original packing.</li>

                <li> The returned products/SKUs should be in original condition of dispatch, i.e. complete with all parts that it was sold with.<br/></li>

                <li> Material should be unused and in saleable condition.</li>

                <li> All combos & kits to be returned in full (no partial returns will be accepted).</li>

                <li> The original invoice must be attached/presented upon returning the merchandise.</li>

                <li> All returns will be accepted in case of supplier issues, transit damage, incorrect SKU dispatch, or expired products.</li> 

                <li> In case of thinking of returning the material, check your invoice to identify that product is not of non- returnable category.</li> 

                <li> Please refer to detailed category wise return conditions.</li>

              </ol>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header><h5>Timeframe</h5></Accordion.Header>
          <Accordion.Body>
           <ol>
             <li> Our quality assurance team and the third party service provider's team will inspect all returned items within 72 hours of the request. Only when it passes our quality assurance test, we will be able to process the return.</li>

             <li> Please refer to detailed category wise return conditions. </li>
          </ol>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header><h5>Method of reimbursement</h5></Accordion.Header>
          <Accordion.Body>
            <ol>
               <li> The refund will be provided in the form of Credit Notes, which will remain valid for 1 year from the date of issuance.</li>
            </ol> 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header><h5>Changes for Reverse Logistics</h5></Accordion.Header>
          <Accordion.Body>
            <ol>
              <li> In the event of return issues such as transit damage, incorrect SKJ dispatch, or expired products <strong> (which are supply-side problems)</strong>, Yellow Daisy will cover the costs associated with reverse logistics.</li>

              <li> For <strong> Customer Related Issues</strong> (wherever we accept the return), customer will have to bear the reverse logistics charges.</li>
          </ol> 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header><h5>Replacement/Rejection Policy</h5></Accordion.Header>
          <Accordion.Body>
            <ol>
          <li> Modular kitchens and wardrobes are made-to-order and hence cannot be replaced or exchanged.</li>  

          <li> Any non-manufacturing defects and damages post receiving delivery will render the product invalid for returns or replacements/exchanges</li> 

          <li> Customised furniture pieces purchased through Yellow Daisy cannot be returned or exchanged.</li>  

          <li> The customized orders or bulk order placed are not part of return policy.</li>  

          <li> Items purchased as part of certain schemes or promotions will not be part of return policy.</li> 
          </ol>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header><h5>Eligibility for Replacement</h5></Accordion.Header>
          <Accordion.Body>
            <ol>
            The product qualifies for replacement only in the following situations: 

             <li>  The product arrives with defects or damage during delivery.</li>  

             <li> Wrong item delivered </li> 

             <li>The received item is incomplete, lacking parts or accessories.</li> 
          </ol>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header><h5>Time limit for Return of Goods</h5></Accordion.Header>
          <Accordion.Body>
            <ol>
          Please refer to detailed category wise return conditions. 
          <li> Products will only be replaced if they are unused and returned in their original packaging, including original price tags, labels, barcodes, user manuals, warranty cards, and invoices, along with any accessories, freebies, and original boxes, subject to quality checks. </li>

          <li> Whether a replacement is accepted or rejected depends on the outcome of the quality check.</li>

          <li> Once the original product is received, the replacement order will be shipped using standard shipping methods.</li>

          <li> If an eligible product is out of stock, it cannot be replaced. However, the customer can return the item according to the terms of the Returns Policy.</li>

          <li> Only once product can be replaced.</li>
          </ol>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default MaterialReturnPolicy;
