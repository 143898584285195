import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Spinner } from "react-bootstrap";

import { AuthorisedGetDataService } from "../Reducer/DataService/GetDataService";
import { AuthorisedDeleteDataService } from "../Reducer/DataService/PostDataService";
import { RefundBreadCrumb } from "../Component/Refund/RefundBreadCrumb";
import { RefundLists } from "../Component/Refund/RefundLists";

export const Refund = (props) => {

    const { theam } = props
    const [isLoading, setIsLoading] = useState(true);
    const [modelShow, setModelShow] = useState(false);
    const [paymentShow, setPaymentShow] = useState(false);
    const [deleteAlertShow, setDeleteAlertShow] = useState(false);
    const [Refunds, setRefunds] = useState([]);
    const [deleteRecordId, setDeleteRecordId] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    useEffect(() => {
        getRefundDetails();
    }, []);

    const getRefundDetails = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetRefund");
        debugger
        setRefunds(response);
        setIsLoading(false);
    }

    const onHide = () => {
        setModelShow(false);
    }
    const handleSubmit = (response) => {
        if (response == "200") {
            setModelShow(false);
            getRefundDetails();
        }
    }
    const onDeleteCancel = () => {
        setDeleteAlertShow(false);
    }
    const onDeleteHandle = (refundId) => {
        setDeleteAlertShow(true);
        setDeleteRecordId(refundId);
    }

    const onDeleteConfirm = async () => {
        const response = await AuthorisedDeleteDataService(`DeleteFromOrder/${deleteRecordId}`);
        if (response == "200") {
            setDeleteAlertShow(false);
            getRefundDetails();
        }
    }
    const showPayment = (totalAmount) => {
        setTotalAmount(totalAmount);
        setPaymentShow(true);
    }

    const onPaymentHide = () => {
        setPaymentShow(false);
    }


    return (
        <Row>
            <Col>
                <RefundBreadCrumb theam={theam} />
                <RefundLists theam={theam} />
            </Col>
        </Row>
    )
}