import { Card, CardBody, CardHeader, Col, FormCheck, Row, Image, Container, Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import { ForgetService } from '../Reducer/DataService/UserDataService';


function ForgetPassword(props) {
    const { show, onHide, onLogin, theam } = props;
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [userName, setUserName] = useState("");

    const handleSubmit = async e => {
        e.preventDefault();
        if (ValidateForm()) {
            var response = await ForgetService(userName);
            console.log(response);
            if (response.isSuccess) {
                setVariant('success')
            }
            else {
                setVariant('danger')
            }
        }
        return false;
    }
    const handleUserName = (e) => {
        setUserName(e.target.value);
        ValidateForm();
    }
    const ValidateForm = () => {

        if (!userName) {
            setEnableSubmit(false);
            return false;
        }
        else {
            setEnableSubmit(true);
            return true;
        }

    }

    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title className="justify-content-center">
                    <h4 className='float-center'> Forget Password  </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Card className="mb-6">
                            <CardBody>
                                <form className="row g-3 needs-validation" novalidate>
                                    <div className="col-12">
                                        <label for="yourUsername" className="form-label">Username</label>
                                        <div className="input-group has-validation">
                                            <input type="text" name="username" className="form-control" id="yourUsername" required onChange={handleUserName} />
                                            <div className="invalid-feedback">Please choose a username.</div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-end">
                                        <Button disabled={enableSubmit} className="btn btn-primary w-50" type="submit" onClick={handleSubmit}>Forget Password </Button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer >
                <div className="col-12 text-center">
                    <a onClick={onLogin}> <p className="small mb-0">Already have an account? Log in</p></a>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
export default ForgetPassword;