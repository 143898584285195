import { Breadcrumb, Card } from "react-bootstrap";

export const RefundBreadCrumb=()=>{
    return (
        <Card>
            <Card.Header>
                <strong className="me-auto">
                    <Breadcrumb>
                        <Breadcrumb.Item href='/home'>Home</Breadcrumb.Item>
                        <Breadcrumb.Item href='#' active>Refund List</Breadcrumb.Item>
                    </Breadcrumb>
                </strong>
            </Card.Header>
        </Card>
    );
}