import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

import { AdminLayout } from "../../Router/AdminLayout";

const PrivateRoute = (props) => {
    const { theam } = props
    const auth = useAuth();
    if (!auth || !auth.user || auth.user.role == "User") {
        return <Navigate to="/" />;
    }

    return <AdminLayout theam={theam} />;
}

export default PrivateRoute;