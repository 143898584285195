import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GetDataService } from "../Reducer/DataService/GetDataService";
import { Button, Card, CardBody, Col, Container, Image, Row, Spinner, Table } from "react-bootstrap";
import { CurrencyRupee } from "react-bootstrap-icons";

export const BuyProduct = (props) => {
    const { theam } = props
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [cartId, setCartId] = useState(searchParams?.get("cartId") || null);
    const [productId, setProductId] = useState(searchParams?.get("productId") || null);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (productId) {
            getProductById(productId);
        }
        else if (cartId) {
            getCartProductsByUserId();
        }

    }, []);

    const getProductById = async (productId) => {
        
        setIsLoading(true);
        var response = await GetDataService(`GetProductById/${productId}`);
        debugger
        let data=[];
        data.push(response);
        setProducts(data);
        setIsLoading(false);
    }

    const getCartProductsByUserId = () => {

    }

    const getRow = (id, cartDetail) => {
        return (
            <tr key={id}>
                <td>
                    <Image src={"data:image/png;base64," + cartDetail.imageData} className="rounded-circle" width="50" height="50" />
                </td>
                <td>
                    {cartDetail.productTitle}
                </td>
                <td>
                    {cartDetail.productCode}
                </td>
                <td>
                    {cartDetail.price}<CurrencyRupee />
                </td>
                <td>
                    {1}
                </td>
                <td>
                    {cartDetail.price * 1}<CurrencyRupee />
                </td>
            </tr>
        )
    }
    const getFooter = (cartDetails) => {
        debugger
        const totalItems = 1;
        const totalPrice = cartDetails.reduce((a, v) => a = a + (v.price * 1), 0)
        return (
            <tr>
                <td colSpan={4} class="text-end">
                    <b>Total Amount: </b>
                </td>
                <td>
                    <b> {totalItems}</b>
                </td>
                <td>
                    <b> {totalPrice}<CurrencyRupee /></b>
                </td>
                <td>
                    <Button className="btn">Order Now</Button>
                </td>
            </tr>
        )
    }
    return (
        <Container>
            {isLoading ?
                <Row className='pe-0' style={{ flex: 1, marginTop: 340, justifyContent: 'center', alignContent: 'center' }} >
                    <Col md={2}>
                        <Spinner animation="grow" variant={theam} size="sm" style={{ flex: 1, alignSelf: 'center' }} />
                        <Spinner animation="grow" variant={theam} style={{ flex: 1, alignSelf: 'center' }} />
                    </Col>
                </Row>
                :
                <Row className='pe-0' style={{ marginTop: 108 }} >
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <h5>Place order</h5>
                            </CardBody>
                            <CardBody>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>
                                                Image
                                            </th>
                                            <th>
                                                Product Title
                                            </th>
                                            <th>
                                                Product Code
                                            </th>
                                            <th>
                                                Rate
                                            </th>
                                            <th>
                                                Quantity
                                            </th>
                                            <th>
                                                Total Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products ? products.map((rowData, rowKey) => getRow(rowKey, rowData)) : ""}
                                        {products ? getFooter(products) : ""}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>}
        </Container>
    )
}