import { useState } from "react";
import { Button, Col, FormControl, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "react-bootstrap";
import { AuthorisedPostDataService } from "../../Reducer/DataService/PostDataService";

export const AddToCartModal = (props) => {
    const { theam,actionType, show, onHide, handleSubmit, formData } = props;
    const [cartData, setCartData] = useState(formData);

    const GetFormTitle = () => {
        if (actionType && actionType === 'UPDATE') {
            return 'Update To Cart';
        }
        else {
            return 'Add To Cart';
        }
    }

    const handleQuantity = (e) => {
        cartData.quantity = e.target.value;
    }
    const onMinusClick = () => {
        cartData.quantity = cartData.quantity - 1;
    }
    const onPlusClick = () => {
        cartData.quantity = cartData.quantity + 1;
    }
    const onSubmit = async () => {
        cartData.customerId = 0;
        const response = await AuthorisedPostDataService(cartData, "AddToCart");
        return await handleSubmit(response);
    }

    const updateChanges = async () => {
        const response = await AuthorisedPostDataService(cartData, "UpdateCart");
        return handleSubmit(response);
    }

    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="sm" aria-labelledby="contained-modal-title-vcenter">
            <ModalHeader closeButton>
                <h5>{GetFormTitle()}</h5>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={7}>
                        <FormControl type="number" min={0} defaultValue={formData.quantity} onChange={handleQuantity} />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
            {actionType == "UPDATE" ?
                    <Button variant="primary" onClick={updateChanges} >Update to Cart</Button>:
                <Button className="btn btn-primary" onClick={onSubmit} >Add to Cart</Button>}
                <Button className="btn btn-danger" onClick={onHide} >Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}