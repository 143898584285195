import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { AuthorisedPostDataService } from '../../../Reducer/DataService/PostDataService';

const SubCategoryEditor = (props) => {
    const { actionType, show, onHide, formData, handleSubmit, categories } = props;
    const [subCategory, setSubCategory] = useState(formData);
    const [isValidated, setIsValidated] = useState(false);
    const [isActive, setIsActive] = useState(formData.status == 1);

    const GetFormTitle = () => {
        if (actionType && actionType === 'UPDATE') {
            return 'Update Sub Category';
        }
        else {
            return 'Add New Sub Category';
        }
    }

    const handleSubCategory = (e) => {
        subCategory.subCategoryName = e.target.value;
        CheckValidation();
    }
    const handleCategory = (e) => {
        subCategory.category = e.target.value;
        CheckValidation();
    }
    const handleDescription = (e) => {
        subCategory.description = e.target.value;
        CheckValidation();
    }

    const handleStatus = (e) => {
        if (e.target.checked) {
            subCategory.status = 1;
            setIsActive(true);
        }
        else {
            subCategory.status = 0;
            setIsActive(false);
        }
        CheckValidation();
    }

    const CheckValidation = () => {
        if (!subCategory.subCategoryName || !subCategory.description || subCategory.category) {
            setIsValidated(false);
        }
        setIsValidated(true);
    }

    const submit = async (e) => {
        const response = await AuthorisedPostDataService(subCategory, "addSubCategory");
        return await handleSubmit(response);
    }

    const updateChanges = async () => {
        const data = {
            subCategoryId: formData.subCategoryId,
            category: subCategory.category ? subCategory.category : formData.category,
            subCategoryName: subCategory.subCategoryName ? subCategory.subCategoryName : formData.subCategoryName,
            description: subCategory.description ? subCategory.description : formData.description,
            status: subCategory.status ? subCategory.status : formData.status
        }
        const response = await AuthorisedPostDataService(data, "UpdateSubCategory");
        return await handleSubmit(response);
    }

    const getOption = (rowKey, category) => {
        return (
            <option value={category.categoryId}>{category.categoryName}</option>
        )
    }
    const getSubCategoryList = () => {
        if (categories) {
            return (
                <Form.Select aria-label="Select Category" defaultValue={formData.category} onChange={handleCategory}>
                    <option>select</option>
                    {categories.map((rowData, rowKey) => getOption(rowKey, rowData))}
                </Form.Select>
            )
        }
        else {
            return (
                <Form.Select aria-label="Select Category">
                    <option>select</option>
                </Form.Select>
            )
        }
    }

    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>
                    {GetFormTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={isValidated} >
                    <Form.Group className="mb-3" controlId="SubCategoryName">
                        <Form.Label>Sub Category Name</Form.Label>
                        <Form.Control type="text" placeholder="Sub Category Name" defaultValue={formData.subCategoryName} onChange={handleSubCategory} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Category">
                        <Form.Label>Category</Form.Label>
                        {getSubCategoryList()}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Description" defaultValue={formData.description} onChange={handleDescription} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="IsActive">
                        <Form.Group className="position-relative mb-3">
                            <Form.Check type="switch" label="Is Active" defaultChecked={formData.status == 1} checked={isActive} onChange={handleStatus} />
                        </Form.Group>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide} >Close</Button>
                {actionType == "UPDATE" ?
                    <Button variant="primary" disabled={!isValidated} onClick={updateChanges} >Update</Button>
                    : <Button variant="primary" disabled={!isValidated} onClick={submit} >Save Changes</Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default SubCategoryEditor;