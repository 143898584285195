import { Alert, Col, Row } from "react-bootstrap";

import { OrderLists } from "../Component/Order/OrderLists";
import { OrderBreadCrumb } from "../Component/Order/OrderBreadCrumb";

export const Orders=()=>{
 return(
    <Row>
        <Col>
           <OrderBreadCrumb />
           <OrderLists />
        </Col>
    </Row>
 )




}