import { BaseUrl } from "../../Constants/ApiConstants";

export const PostDataService = async (data, method) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    const response = await fetch(BaseUrl + method, requestOptions);
    return await response.json();
}

export const AuthorisedPostDataService = async (data, method) => {    
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${loggedInUser.token}`
        },
        body: JSON.stringify(data)
    };
    debugger
    const response = await fetch(BaseUrl + method, requestOptions);
    return response.status;
}

export const PostUploadProductImages = async (files, method) => { 
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    var imageType = /image.*/;
    const form_Data = new FormData();
    
    if (files) {
        files.forEach((file, i) => {
            if (file.type.match(imageType)) {
                form_Data.append(`files`, file);
            }
        })
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${loggedInUser.token}`
        },
        body: form_Data
    };
    debugger
    const response = await fetch(BaseUrl + method, requestOptions);
    return response.status;
}

export const AuthorisedDeleteDataService = async (method) => { 
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${loggedInUser.token}`
        },
        body: []
    };
    debugger
    const response = await fetch(BaseUrl + method, requestOptions);
    return response.status;
}