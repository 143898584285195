import { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";

import { AuthorisedGetDataService } from "../Reducer/DataService/GetDataService";
import { ProductListBreadCrumb } from "../Component/Product/ProductListBreadCrumb";
import { ProductViewer } from "../Component/Product/ProductViewer";
import { DefaultPageSize, productDefaultGeneralData } from "../Constants/DefaultDataConstant";
import { ProductEditor } from "../Component/Product/ProductEditor";
import { UploadProductImage } from "../Component/ProductImages/UploadProductImage";

const Products = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [modelShow, setModelShow] = useState(false);
    const [modelShowUpload, setModelShowUpload] = useState(false);
    const [productModal, setProductModal] = useState([]);
    const [productData, setProductData] = useState(productDefaultGeneralData);
    const [actionType, setActionType] = useState('NEW');
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [productId, setProductId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getProducts(currentPage);
    }, []);

    const getProducts = async (pageNumber) => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService(`GetAllProducts/${pageNumber}/${DefaultPageSize}`);
        setProductModal(response);
        setIsLoading(false);
    }

    const newRecord = () => {
        setActionType('NEW');
        setModelShow(true);
        setProductData(productDefaultGeneralData);
    }

    const onHide = () => {
        setModelShow(false);
    }
    const onHideUpload = () => {
        setModelShowUpload(false);
    }
    const editRecord = (product) => {
        setProductData({
            productId: product.productId,
            productCode: product.productCode,
            productTitle: product.productTitle,
            category: product.category,
            subCategory: product.subCategory,
            measuringUnit: product.measuringUnit,
            tag: product.tag,
            vendor: product.vendor,
            price: product.price,
            inStockQuantity: product.inStockQuantity,
            description: product.description
        });
        setActionType("UPDATE");
        setModelShow(true);
    }

    const onUpload = (productId) => {
        setProductId(productId);
        setModelShowUpload(true);
    }

    const onNextPageHandle = (currentPage) => {
        setCurrentPage(currentPage);
        getProducts(currentPage);
    }

    const handleSubmit = async (result) => {
        debugger
        if (result==200) {
            setMessage("Record save successfully.");
            setVariant("success");
            await getProducts(currentPage);
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }
    const handleSubmitUpload = (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
            setModelShowUpload(false);
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }
    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <ProductListBreadCrumb />
                {GetAlert()}
                <ProductViewer
                    products={productModal?.products}
                    isLoading={isLoading}
                    onEdit={editRecord}
                    onUpload={onUpload}
                    onNew={newRecord}
                    totalRecords={productModal.totalCount}
                    pageSize={DefaultPageSize}
                    currentPage={currentPage}
                    onPageClick={onNextPageHandle} />
                <ProductEditor show={modelShow} onHide={onHide} actionType={actionType} formData={productData} handleSubmit={handleSubmit} />
                <UploadProductImage show={modelShowUpload} onHide={onHideUpload} handleSubmit={handleSubmitUpload} productId={productId} />
            </Col>
        </Row>
    )
}

export default Products;