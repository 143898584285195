import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

import { AuthorisedGetDataService } from '../../Reducer/DataService/GetDataService';
import MeasuringUnitBreadCrumb from '../../Component/MasterComponent/MeasuringUnit/MeasuringUnitBreadCrumb';
import MeasuringUnitEditor from '../../Component/MasterComponent/MeasuringUnit/MeasuringUnitEditor';
import MeasuringUnitViewer from '../../Component/MasterComponent/MeasuringUnit/MeasuringUnitViewer';
import { measuringUnitDefaultData } from '../../Constants/DefaultDataConstant';

const MeasuringUnitPage=()=>{
    const [modelShow, setModelShow] = useState(false);
    const [actionType, setActionType] = useState('NEW');
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState(measuringUnitDefaultData);

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetAllMeasuringUnits");
        setCategories(response);
        setIsLoading(false);
    }

    const newRecord = () => {
        setActionType('NEW');
        setModelShow(true);
        setFormData(measuringUnitDefaultData);
    }

    const onHide = () => {
        setModelShow(false);
    }
    const onEditRecord = (measuringUnit) => {
        setFormData({
            measuringUnitId: measuringUnit.measuringUnitId,
            unitName: measuringUnit.unitName,
            description: measuringUnit.description,
            status: measuringUnit.status
        });
        setActionType("UPDATE");
        setModelShow(true);
    }
    const handleSubmit = async (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
            await getCategories();
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <MeasuringUnitBreadCrumb />
                {GetAlert()}
                <MeasuringUnitViewer onNew={newRecord} onEdit={onEditRecord} categories={categories} isLoading={isLoading} />
                <MeasuringUnitEditor show={modelShow} onHide={onHide} actionType={actionType} formData={formData} handleSubmit={handleSubmit} />
            </Col>
        </Row>
    )
}

export default MeasuringUnitPage