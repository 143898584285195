import { Button, Card, CardBody, Col, Form, Image, Row, Table } from "react-bootstrap";
import { useState } from "react";

export const OrderLists = (props) =>{

    const [orders, set] = useState([]);
const handleChange=()=>{
    
}
   return(
       <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <Card.Title>Orders Information</Card.Title>           
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
            <Card.Title>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="Category">
                                <Form.Label>Order Status </Form.Label>
                                <Form.Select onChange={handleChange} >
                                    <option>Select</option>
                                    <option value={0}>Draft</option>
                                    <option value={1}>Approved</option>
                                    <option value={2}>Dispatched</option>
                                    <option value={3}>Delivered</option>
                                    <option value={4}>RefundInitiated</option>
                                    <option value={5}>Refund</option>
                                    <option value={6}>Canceled</option>                                   
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <hr />
                    </Row>
                </Card.Title>
                <Row>
                    <Col md={12}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>
                                       OrderId
                                    </th>
                                    <th>
                                      CustomerId
                                    </th>
                                    <th>
                                      Payment Type
                                    </th>
                                    <th>
                                       PaymentStatus
                                    </th>
                                    <th>
                                       Approved Date  
                                    </th>
                                    <th>
                                       Order Date
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
       </Card>

   )


}