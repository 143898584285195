import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Search } from 'react-bootstrap-icons';

const SearchComponent = (props) => {
  const {theam}=props
    return (
        <Form className="d-flex w-50">
        <Form.Control
          type="search"
          placeholder="Search"
          className="me-2"
          aria-label="Search"
          size="xxl"
        />
        <Button variant={theam}><Search /></Button>
        </Form>
    );
}

export default SearchComponent;