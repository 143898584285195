import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

import TagEditor from '../../Component/MasterComponent/Tags/TagEditor';
import TagViewer from '../../Component/MasterComponent/Tags/TagViewer';
import TagBreadCrumb from '../../Component/MasterComponent/Tags/TagBreadCrumb';
import { AuthorisedGetDataService } from '../../Reducer/DataService/GetDataService';
import { tagDefaultData } from '../../Constants/DefaultDataConstant';

const TagPage=()=>{
    const [modelShow, setModelShow] = useState(false);
    const [actionType, setActionType] = useState('NEW');
    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState(tagDefaultData);

    useEffect(() => {
        getAllTagss();
    }, []);

    const getAllTagss = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetAllTags");
        debugger
        setTags(response);
        setIsLoading(false);
    }


    const newRecord = () => {
        setActionType('NEW');
        setModelShow(true);
        setFormData(tagDefaultData);
    }

    const onHide = () => {
        setModelShow(false);
    }
    const onEditRecord = (tag) => {
        setFormData({
            tagId: tag.tagId,
            tagTitle: tag.tagTitle,
            description: tag.description,
            status: tag.status
        });
        setActionType("UPDATE");
        setModelShow(true);
    }
    const handleSubmit = async (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
            await getAllTagss();
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <TagBreadCrumb />
                {GetAlert()}
                <TagViewer onNew={newRecord} onEdit={onEditRecord} tags={tags} isLoading={isLoading} />
                <TagEditor show={modelShow} onHide={onHide} actionType={actionType} formData={formData} handleSubmit={handleSubmit} />
            </Col>
        </Row>
    )
}

export default TagPage;