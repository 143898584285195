import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';

import logo from '../../Images/LK Logo.jpg';

const LogoComponent = (props) => {
    const { theam } = props
    return (
        <div className="d-flex align-items-center justify-content-between">
            <Navbar.Brand href="./home" className="logo d-flex align-items-center">
                <Image roundedCircle="true" src={logo} width="40" height="40" className="d-inline-block align-top" alt="LKRAJ" />
            </Navbar.Brand>
            <Navbar.Brand href="#home" className="logo d-flex align-items-center pe-0">
                <div variant={theam}><h5>ABC Pvt Ltd</h5></div>
            </Navbar.Brand>
        </div>
    )
}

export default LogoComponent;