import { Card, CardBody, Col, Form, Row, Image, Spinner, Button, Modal, CardFooter } from 'react-bootstrap';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

import { useAuth } from '../Component/Auth/AuthProvider';
import { LoginService } from '../Reducer/DataService/UserDataService';

const LoginPage = (props) => {
    const { show, loginDone, onHide, onSignup, onForget,theam } = props;
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [showAlert, setShowAlert] = useState(true);
    const [message, setMessage] = useState("");
    const [variant, setVariant] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (userName && password) {
            const response = await LoginService({
                userName: userName,
                password: password
            });
            if (response.isSuccess) {
                auth.loginAction(response);
                setVariant('success')
                setMessage("You are logged in.");
                loginDone();
            }
            else {
                setVariant('danger')
                setMessage(response.message);
            }
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2500);
            setIsLoading(false);
            return;
        }
        return false;

    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }

    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title className="justify-content-center">
                    <h4 className='float-center'> Login to Your Account </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        {GetAlert()}
                        <Form className="row g-3 needs-validation" novalidate>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>User Name </Form.Label>
                                <Form.Control type="text" placeholder="username" onChange={e => setUserName(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Password </Form.Label>
                                <Form.Control type="password" placeholder="password" onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="rememberMe">
                                <Form.Group className="position-relative mb-3">
                                    <Form.Check type="switch" label="Remember me" defaultValue={true} />
                                </Form.Group>
                            </Form.Group>
                        </Form>
                        <Row>
                            <Col md={12} className="text-center">
                                {isLoading ? <Button className="btn btn-primary w-50" type="submit" >
                                    <Spinner animation='grow' /> Loaging...
                                </Button> :
                                    <Button className="btn btn-primary w-50" type="submit" onClick={handleSubmit}>
                                        Login
                                    </Button>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="outline-primary" className="w-100" onClick={onForget} >Forget password </Button>
                <Button variant="outline-primary" className="w-100" onClick={onSignup} >Create new account </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LoginPage;