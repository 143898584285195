import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { PencilSquare, PlusSquare } from "react-bootstrap-icons";
import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";
import { DefaultDateFormat } from "../../Utility";

export const ProductVarientList=(props)=>{
    const { onNew, onEdit, isLoading, product,onProductChange } = props;
    const [products, setProducts] = useState([]);
    const [productVarients, setProductVarients] = useState([]);

    useEffect(() => {
        getAllProducts();
    }, []);

    const getAllProducts = async () => {
        var response = await AuthorisedGetDataService("GetProductsForDropDown");
        setProducts(response);
    }
    const getProductVarients = async (productId) => {
        var response = await AuthorisedGetDataService("GetProductVarientsById/" + productId);
        debugger
        setProductVarients(response);
    }

    const handleChange = async (e) => {
        getProductVarients(e.target.value);
        onProductChange(e.target.value);
    }

    const getProductOption = (row) => {
        return (
            <option value={row.productId}>{row.productTitle} | {row.productCode}</option>
        )
    }

    const getRow = (id, productVarient) => {
        return (
            <tr key={id}>
                <td>
                    {productVarient.productVarientId}
                </td>
                <td>
                   {productVarient.varientName}
                </td>
                <td>
                   {productVarient.varientValue}
                </td>
                <td>
                    {DefaultDateFormat(productVarient.createdDate)}
                </td>
                <td>
                    <Button className='ps-2' variant="primary" onClick={() => onEdit(productVarient)}><PencilSquare /></Button> &nbsp;
                </td>
            </tr>
        )
    }

    return (isLoading ? <h3>loading...</h3> : (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <Card.Title>Products Information
                            <a className='btn btn-primary float-end' variant="primary" onClick={onNew}><PlusSquare /> New Varient</a></Card.Title>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Title>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="Category">
                                <Form.Label>Product </Form.Label>
                                <Form.Select onChange={handleChange} defaultValue={product ? product.productId : "Select"}>
                                    <option>Select</option>
                                    {products ? products.map((row) => getProductOption(row)) : ""}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <hr />
                    </Row>
                </Card.Title>
                <Row>
                    <Col md={12}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>
                                        Id
                                    </th>
                                    <th>
                                        Varient
                                    </th>
                                    <th>
                                        Varient Value
                                    </th>
                                    <th>
                                        Created Date
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {productVarients.map((rowData, rowKey) => getRow(rowKey, rowData))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    ));
}