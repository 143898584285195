import { Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import { Trash, Upload } from "react-bootstrap-icons";
import { DefaultDateFormat } from "../../Utility";
import { useEffect, useState } from "react";
import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";

export const ProductImageLists = (props) => {
    const { onNew, isLoading, product,onProductChange } = props;

    const [productId, setproductId] = useState(product ? product.productId : 0);
    const [products, setProducts] = useState([]);
    const [productImages, setProductImages] = useState([]);

    useEffect(() => {
        getAllProducts();
    }, []);

    const getAllProducts = async () => {
        var response = await AuthorisedGetDataService("GetProductsForDropDown");
        setProducts(response);
    }

    const uploadNew = (e) => {
        if (productId && productId != 0) {
            onNew();
        }
    }

    const handleChange = async (e) => {
        const result = products.filter(sub => sub.productId == e.target.value);
        getProductImages(e.target.value);
        setproductId(e.target.value);
        onProductChange(e.target.value);
    }

    const getProductImages = async (productId) => {
        var response = await AuthorisedGetDataService("GetProductImagesById/" + productId);
        setProductImages(response);
    }

    const getProductOption = (row) => {
        return (
            <option value={row.productId}>{row.productTitle} | {row.productCode}</option>
        )
    }

    const getRow = (id, productImage) => {
        return (
            <tr key={id}>
                <td>
                    {productImage.productImageId}
                </td>
                <td>
                    <Image width={50} src={"data:image/png;base64,"+ productImage.fileData} />
                </td>
                <td>
                    {productImage.fileName}
                </td>
                <td>
                    {DefaultDateFormat(productImage.createdDate)}
                </td>
                <td>
                    <Button className='ps-2' variant="danger" ><Trash /></Button> &nbsp;
                </td>
            </tr>
        )
    }

    return (isLoading ? <h3>loading...</h3> : (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <Card.Title>Products Information
                            <a className='btn btn-primary float-end' variant="primary" onClick={uploadNew}><Upload /> Upload New Image</a></Card.Title>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Title>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="Category">
                                <Form.Label>Product </Form.Label>
                                <Form.Select onChange={handleChange} defaultValue={product ? product.productId : "Select"}>
                                    <option>Select</option>
                                    {products ? products.map((row) => getProductOption(row)) : ""}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <hr />
                    </Row>
                </Card.Title>
                <Row>
                    <Col md={12}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>
                                        Id
                                    </th>
                                    <th>
                                        Image
                                    </th>
                                    <th>
                                        Image Name
                                    </th>
                                    <th>
                                        Uploaded Date
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {productImages.map((rowData, rowKey) => getRow(rowKey, rowData))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    ));
}