import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

import HeaderComponent from '../Component/Header/HeaderComponent';
import FooterComponent from '../Component/Footer/FooterComponent';
import LoginPage from "../Pages/LoginPage";
import Register from "../Pages/Register";
import { CategoryTiles } from "../Component/Home/CategoryTiles";
import ForgetPassword from "../Pages/ForgetPassword";
 



const HomeLayout = (props) => {

    const { theam } = props
    const [loginShow, setLoginShow] = useState(false);
    const [signupShow, setSignupShow] = useState(false);
    const [forgetShow, setForgetShow] = useState(false);

    const showLogin = () => {        
        setLoginShow(true);
        setSignupShow(false);        
    }
    const onLoginHide=()=>{
        setLoginShow(false);
    }

    const onSignup=()=>{
        setSignupShow(true);
        setLoginShow(false);
    }

    const onSignupHide=()=>{
        setSignupShow(false);
    }
    const onForget = () => {        
        setForgetShow(true);
        setLoginShow(false);
        setSignupShow(false);        
    }
    const onForgetHide=()=>{
        setForgetShow(false);
    }

    
    return (
        <Container fluid >
            <HeaderComponent  onLogin={showLogin} theam={theam}/>
            <CategoryTiles theam={theam} />  
            <LoginPage show={loginShow} onHide={onLoginHide} onSignup={onSignup} onForget={onForget} theam={theam}/>
            <Register show={signupShow} onHide={onSignupHide} onLogin={showLogin} theam={theam}/>
            <ForgetPassword show={forgetShow} onHide={onForgetHide} onLogin={showLogin} theam={theam}/>       
            <Outlet />
            <FooterComponent  theam={theam}/>
        </Container>
    )
}

export default HomeLayout;