import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';

const VendorBreadCrumb = () =>{
    return (
        <Card>
            <Card.Header>
                <strong className="me-auto">
                    <Breadcrumb>
                        <Breadcrumb.Item href='/home'>Home</Breadcrumb.Item>
                        <Breadcrumb.Item href='/Vendor' active>Vendor</Breadcrumb.Item>
                    </Breadcrumb>
                </strong>
            </Card.Header>
        </Card>
    );
}

export default VendorBreadCrumb;