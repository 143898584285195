import { useEffect, useState } from "react";
import {  Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import { categoryDefaultData, measuringUnitDefaultData, subCategoryDefaultData, tagDefaultData, vendorDefaultData } from "../../Constants/DefaultDataConstant";
import { PlusSquare } from "react-bootstrap-icons";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import VendorEditor from "../MasterComponent/Vendor/VendorEditor";
import TagEditor from "../MasterComponent/Tags/TagEditor";
import MeasuringUnitEditor from "../MasterComponent/MeasuringUnit/MeasuringUnitEditor";
import SubCategoryEditor from "../MasterComponent/SubCategory/SubCategoryEditor";
import CategoryEditor from "../MasterComponent/Category/CategoryEditor";
import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";
import { AuthorisedPostDataService } from "../../Reducer/DataService/PostDataService";


export const ProductEditor = (props) => {
    const { actionType, show, formData, onHide, handleSubmit } = props;
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [filterSubCategoies, setFilterSubCategoies] = useState([]);
    const [measuringUnits, setMeasuringUnits] = useState([]);
    const [tags, setTags] = useState([]);
    const [vandors, setVandors] = useState(props.vandors || []);
    const [product, setProduct] = useState(formData);
    const [isloading, setIsloading] = useState(false);
    const [categoryModelShow, setCategoryModelShow] = useState(false);
    const [subCategoryModelShow, setSubCategoryModelShow] = useState(false);
    const [measuringUnitEditorShow, setMeasuringUnitEditorShow] = useState(false);
    const [tagEditorModelShow, setTagEditorModelShow] = useState(false);
    const [vendorEditorModelShow, setVendorEditorModelShow] = useState(false);

    useEffect(() => {
        getAllActiveSubCategories();
        getAllActiveCategories();
        getAllActiveMeasuringUnits();
        getAllActiveTags();
        getAllActiveVendors();
    }, []);
    const getAllActiveCategories = async () => {
        var response = await AuthorisedGetDataService("GetAllActiveCategories");
        setCategories(response);
    }   
    const getAllActiveSubCategories = async () => {
        var response = await AuthorisedGetDataService(`GetAllActiveSubCategories`);
        setFilterSubCategoies(response);
        return setSubCategories(response);
    }
    const getAllActiveMeasuringUnits = async () => {
        var response = await AuthorisedGetDataService("GetAllActiveMeasuringUnits");
        setMeasuringUnits(response);
    }
    const getAllActiveTags = async () => {
        var response = await AuthorisedGetDataService("GetAllActiveTags");
        setTags(response);
    }
    const getAllActiveVendors = async () => {
        var response = await AuthorisedGetDataService("GetAllActiveVendors");
        setVandors(response);
    }

    const handleProductTitle = (e) => {
        product.productTitle = e.target.value;
    }

    const handleProductCode = (e) => {
        product.productCode = e.target.value;
    }

    const handleCategory = async (e) => {
        product.category = e.target.value;
        const result = subCategories.filter(sub => sub.category == e.target.value);
        return setFilterSubCategoies(result);
    }

    const handleSubCategory = (e) => {
        product.subCategory = e.target.value;
    }
    const handleMeasuringUnit = (e) => {
        product.measuringUnit = e.target.value;
    }
    const handleTag = (e) => {
        product.tag = e.target.value;
    }

    const handleVendor = (e) => {
        product.vendor = e.target.value;
    }

    const handleDescription = (e,editor) => {
        product.description = editor.getData();
    }
    const handlePrice = (e) => {
        product.price = e.target.value;
    }

    const handleInStockQuantity = (e) => {
        product.inStockQuantity = e.target.value;
    }
    const validateForm = () => {
        if (!product
            || product.productCode == ""
            || product.productTitle == ""
            || product.category == ""
            || product.subCategory == ""
            || product.measuringUnit == ""
            || product.tag == ""
            || product.price == 0
            || product.inStockQuantity == 0
            || product.vendor == ""
            || product.description == "") {
            return false;
        }
        return true;
    }

    const saveRecord = async (e) => {
        if (validateForm()) {
            setIsloading(true);
            debugger
            const response = await AuthorisedPostDataService(product, "AddProduct");
            if (response == 200) {
                setIsloading(false);
                return handleSubmit(response);
            }

        }
    }
    const updateChanges = async () => {
        setIsloading(true);
        const data = {
            productId: formData.productId,
            productCode: product.productCode ? product.productCode : formData.productCode,
            productTitle: product.productTitle ? product.productTitle : formData.productTitle,
            category: product.category ? product.category : formData.category,
            subCategory: product.subCategory ? product.subCategory : formData.subCategory,
            measuringUnit: product.measuringUnit ? product.measuringUnit : formData.measuringUnit,
            tag: product.tag ? product.tag : formData.tag,
            vendor: product.vendor ? product.vendor : formData.vendor,
            price: product.price ? product.price : formData.price,
            inStockQuantity: product.inStockQuantity ? product.inStockQuantity : formData.inStockQuantity,
            description: product.description ? product.description : formData.description
        }
        const response = await AuthorisedPostDataService(data, "UpdateProduct");
        if (response == 200) {
            setIsloading(false);
            return handleSubmit(response);
        }
        
    }
    
    const getCategoryOption = (row) => {
        return (
            <option value={row.categoryId}>{row.categoryName}</option>
        )
    }
    const getSubCategoryOption = (row) => {
        return (
            <option value={row.subCategoryId}>{row.subCategoryName}</option>
        )
    }
    const getMeasuringUnitOption = (row) => {
        return (
            <option value={row.measuringUnitId}>{row.unitName}</option>
        )
    }
    const getTagOption = (row) => {
        return (
            <option value={row.tagId}>{row.tagTitle}</option>
        )
    }
    const getVandorOption = (row) => {
        return (
            <option value={row.vendorId}>{row.vendorName}</option>
        )
    }
    const GetFormTitle = () => {
        if (actionType && actionType === 'UPDATE') {
            return 'Update Category';
        }
        else {
            return 'Add New Product';
        }
    }
    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>
                    {GetFormTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>                    
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="Category">
                            <Form.Label>Category <a onClick={() => setCategoryModelShow(true)} className='ps-2'> <PlusSquare /></a></Form.Label>
                            <Form.Select onChange={handleCategory} defaultValue={formData.category}>
                                <option>Select</option>
                                {categories ? categories.map((row) => getCategoryOption(row)) : ""}
                            </Form.Select>
                            <CategoryEditor show={categoryModelShow} onHide={() => setCategoryModelShow(false)} formData={categoryDefaultData} actionType={'NEW'} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="SubCategory">
                            <Form.Label>Sub Category <a onClick={() => setSubCategoryModelShow(true)} className='ps-2'> <PlusSquare /></a></Form.Label>
                            <Form.Select onChange={handleSubCategory} defaultValue={formData.subCategory}>
                                <option>Select</option>
                                {filterSubCategoies ? filterSubCategoies.map((row) => getSubCategoryOption(row)) : ""}
                            </Form.Select>
                            <SubCategoryEditor show={subCategoryModelShow} onHide={() => setSubCategoryModelShow(false)} formData={subCategoryDefaultData} actionType={'NEW'} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3" controlId="MeasuringUnit">
                            <Form.Label>Measuring Unit <a onClick={() => setMeasuringUnitEditorShow(true)} className='ps-2'> <PlusSquare /></a></Form.Label>
                            <Form.Select onChange={handleMeasuringUnit} defaultValue={formData.measuringUnit}>
                                <option>Select</option>
                                {measuringUnits ? measuringUnits.map((row) => getMeasuringUnitOption(row)) : ""}
                            </Form.Select>                            
                            <MeasuringUnitEditor show={measuringUnitEditorShow} onHide={() => setMeasuringUnitEditorShow(false)} formData={measuringUnitDefaultData} actionType={'NEW'} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3" controlId="Tag">
                            <Form.Label>Tags <a onClick={() => setTagEditorModelShow(true)} className='ps-2'> <PlusSquare /></a></Form.Label>
                            <Form.Select onChange={handleTag} defaultValue={formData.tag}>
                                <option>Select</option>
                                {tags ? tags.map((row) => getTagOption(row)) : ""}
                            </Form.Select>                           
                            <TagEditor show={tagEditorModelShow} onHide={() => setTagEditorModelShow(false)} formData={tagDefaultData} actionType={'NEW'} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3" controlId="Vendor">
                            <Form.Label>Vendor <a onClick={() => setVendorEditorModelShow(true)} className='ps-2'> <PlusSquare /></a></Form.Label>
                            <Form.Select onChange={handleVendor} defaultValue={formData.vendor} >
                                <option>Select</option>
                                {vandors ? vandors.map((row) => getVandorOption(row)) : ""}
                            </Form.Select>
                            <VendorEditor show={vendorEditorModelShow} onHide={() => setVendorEditorModelShow(false)} formData={vendorDefaultData} actionType={'NEW'} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="ProductTitle">
                            <Form.Label>Product Title</Form.Label>
                            <Form.Control type="text" placeholder="Product Title" defaultValue={formData.productTitle} onChange={handleProductTitle} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="ProductCode">
                            <Form.Label>Product Code</Form.Label>
                            <Form.Control type="text" placeholder="Product Code" defaultValue={formData.productCode} onChange={handleProductCode} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="Price">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="number" placeholder="Price" defaultValue={formData.price} onChange={handlePrice} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="Quantity">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control type="number" placeholder="Quantity" defaultValue={formData.inStockQuantity} onChange={handleInStockQuantity} />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-5" controlId="Description">
                            <Form.Label>Product Description</Form.Label>
                            <CKEditor editor={ ClassicEditor } data={formData.description} onChange={ handleDescription} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                {isloading ? <Button className='btn btn-primary' > <Spinner animation='grow' /> Saving ...</Button> :
                    actionType == "UPDATE" ?<Button variant="primary" onClick={updateChanges} >Update</Button>
                    : <Button className='btn btn-primary' onClick={saveRecord} > Save</Button>}
            </Modal.Footer>
        </Modal>
    )
}