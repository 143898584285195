import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { AuthorisedPostDataService } from '../../../Reducer/DataService/PostDataService';

const CategoryEditor = (props) => {
    const { actionType, show, onHide, formData, handleSubmit, productGroups } = props;
    const [category, setCategory] = useState(formData);
    const [isValidated, setIsValidated] = useState(false);
    const [isActive, setIsActive] = useState(formData.status == 1);

    const GetFormTitle = () => {
        if (actionType && actionType === 'UPDATE') {
            return 'Update Category';
        }
        else {
            return 'Add New Category';
        }
    }

    const handleProductGroup=(e)=>{
        category.productGroupId = e.target.value;
        CheckValidation();
    }

    const handleCategory = (e) => {
        category.categoryName = e.target.value;
        CheckValidation();
    }

    const handleDescription = (e) => {
        category.description = e.target.value;
        CheckValidation();
    }

    const handleStatus = (e) => {
        if (e.target.checked) {
            category.status = 1;
            setIsActive(true);
        }
        else {
            category.status = 0;
            setIsActive(false);
        }
        CheckValidation();
    }

    const CheckValidation = () => {
        if (!category.categoryName || !category.description) {
            setIsValidated(false);
        }
        setIsValidated(true);
    }

    const submit = async (e) => {
        const response = await AuthorisedPostDataService(category, "addCategory");
        return await handleSubmit(response);
    }
    const updateChanges = async () => {
        const data = {
            categoryId: formData.categoryId,
            productGroupId:category.productGroupId ? category.productGroupId : formData.productGroupId,
            categoryName: category.categoryName ? category.categoryName : formData.categoryName,
            description: category.description ? category.description : formData.description,
            status: category.status ? category.status : formData.status
        }
        const response = await AuthorisedPostDataService(data, "UpdateCategory");
        return await handleSubmit(response);
    }

    const getOption = (rowKey, productGroup) => {
        return (
            <option value={productGroup.productGroupId}>{productGroup.groupName}</option>
        )
    }

    const getProductGroupList = () => {
        return (
            <Form.Select aria-label="Select Product Group" defaultValue={formData.category} onChange={handleProductGroup}>
                <option>select</option>
                {productGroups ? productGroups.map((rowData, rowKey) => getOption(rowKey, rowData)) : ""}
            </Form.Select>
        )
    }

    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>
                    {GetFormTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={isValidated} >
                    <Form.Group className="mb-3" controlId="CategoryName">
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control type="text" placeholder="Category Name" defaultValue={formData.categoryName} onChange={handleCategory} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="ProductGroup">
                        <Form.Label>Product Group</Form.Label>
                        {getProductGroupList()}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Description" defaultValue={formData.description} onChange={handleDescription} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="IsActive">
                        <Form.Group className="position-relative mb-3">
                            <Form.Check type="switch" label="Is Active" defaultValue={formData.status == 1} checked={isActive} onChange={handleStatus} />
                        </Form.Group>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                {actionType == "UPDATE" ?
                    <Button variant="primary" disabled={!isValidated} onClick={updateChanges} >Update</Button>
                    : <Button variant="primary" disabled={!isValidated} onClick={submit} >Save Changes</Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default CategoryEditor;