import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { AuthorisedPostDataService } from '../../../Reducer/DataService/PostDataService';

const MeasuringUnitEditor = (props) => {
    const { actionType, show, onHide, formData, handleSubmit } = props;
    const [measuringUnit, setMeasuringUnit] = useState(formData);
    const [isValidated, setIsValidated] = useState(false);
    const [isActive, setIsActive] = useState(formData.status == 1);

    const GetFormTitle = () => {
        if (actionType && actionType === 'UPDATE') {
            return 'Update MeasuringUnit';
        }
        else {
            return 'Add New MeasuringUnit';
        }
    }

    const handlemeasuringUnit = (e) => {
        measuringUnit.unitName = e.target.value;
        CheckValidation();
    }

    const handleDescription = (e) => {
        measuringUnit.description = e.target.value;
        CheckValidation();
    }

    const handleStatus = (e) => {
        if (e.target.checked) {
            measuringUnit.status = 1;
            setIsActive(true);
        }
        else {
            measuringUnit.status = 0;
            setIsActive(false);
        }
        CheckValidation();
    }

    const CheckValidation = () => {
        if (!measuringUnit.unitName || !measuringUnit.description) {
            setIsValidated(false);
        }
        setIsValidated(true);
    }

    const submit = async (e) => {
        const response = await AuthorisedPostDataService(measuringUnit, "AddMeasuringUnit");
        return await handleSubmit(response);
    }
    const updateChanges = async () => {
        const data = {
            measuringUnitId: formData.measuringUnitId,
            unitName: measuringUnit.unitName ? measuringUnit.unitName : formData.unitName,
            description: measuringUnit.description ? measuringUnit.description : formData.description,
            status: measuringUnit.status ? measuringUnit.status : formData.status
        }
        const response = await AuthorisedPostDataService(data, "UpdateMeasuringUnit");
        return await handleSubmit(response);
    }
    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>
                    {GetFormTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={isValidated} >
                    <Form.Group className="mb-3" controlId="unitName">
                        <Form.Label>Unit Name</Form.Label>
                        <Form.Control type="text" placeholder="Unit Name" defaultValue={formData.unitName} onChange={handlemeasuringUnit} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Description" defaultValue={formData.description} onChange={handleDescription} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="IsActive">
                        <Form.Group className="position-relative mb-3">
                            <Form.Check type="switch" label="Is Active" defaultValue={formData.status == 1} checked={isActive} onChange={handleStatus} />
                        </Form.Group>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                {actionType == "UPDATE" ?
                    <Button variant="primary" disabled={!isValidated} onClick={updateChanges} >Update</Button>
                    : <Button variant="primary" disabled={!isValidated} onClick={submit} >Save Changes</Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default MeasuringUnitEditor;