import { PencilSquare, PlusSquare, Trash } from 'react-bootstrap-icons';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { DefaultDateFormat } from '../../../Utility';

const MeasuringUnitViewer = (props) => {
    const { onNew, onEdit, isLoading, categories } = props;

    const onDeleteHandle = (measuringUnitId) => {

    }
    const getRow = (id, measuringUnit) => {
        return (
            <tr key={id}>
                <td>
                    {measuringUnit.measuringUnitId}
                </td>
                <td>
                    {measuringUnit.unitName}
                </td>
                <td>
                    {measuringUnit.description}
                </td>
                <td>
                    {measuringUnit.status == 1 ? "Active" : "InActive"}
                </td>
                <td>
                    {DefaultDateFormat(measuringUnit.createdDate)}
                </td>
                <td>
                    <Button className='ps-2' variant="primary" onClick={()=>onEdit(measuringUnit)}><PencilSquare /></Button> &nbsp;
                </td>
            </tr>
        )
    }

    return (isLoading ? <h3>loading...</h3> : (
        <Card>
            <Card.Header>
                <Row >
                    <Col>
                        <Card.Title>Measuring Unit Information
                        <Button className='float-end' variant="primary" onClick={onNew}><PlusSquare /> New</Button></Card.Title>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    Id
                                </th>
                                <th>
                                    Unit Name
                                </th>
                                <th>
                                    Description
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Created Date
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((rowData, rowKey) => getRow(rowKey, rowData))}
                        </tbody>
                    </Table>
                </Col>
            </Card.Body>
        </Card>
    )
    );
}

export default MeasuringUnitViewer;