import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Collapse from 'react-bootstrap/Collapse';
import { Grid, Record, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useState } from 'react';

const SidebarComponent = (props) => {
    const { theam } = props;
    const [expandMasterData, setExpandMasterData] = useState(true);

    var getIcon = (isvisible) => {

        let icon = <ChevronDown ></ChevronDown>;
        if (isvisible) {
            icon = <ChevronUp ></ChevronUp>;
        }

        return (icon);
    }

    return (
        <Navbar className="ps-2 pe-0" bg={theam} variant={theam} style={{fontWeight: 'bold'}}>
            <Nav className="d-md-block sidebar" as="ul" >
                <Nav.Item as="li">
                    <Nav.Link href="/home/Dashboard" ><Grid></Grid> Dashboard </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" variant={theam}>
                    <Nav.Link href='/admin/productGroup'><Record></Record> Product Group </Nav.Link>
                    <Nav.Link href='/admin/Category'><Record></Record> Category </Nav.Link>
                    <Nav.Link href='/admin/SubCategory'><Record></Record> Sub Category </Nav.Link>
                    <Nav.Link href='/admin/MeasuringUnit'><Record></Record> Measuring Unit </Nav.Link>
                    <Nav.Link href='/admin/Tag'><Record></Record> Tag </Nav.Link>
                    <Nav.Link href='/admin/Vendor'><Record></Record> Vendor </Nav.Link>
                    <Nav.Link href='/admin/Varient'><Record></Record> Varient </Nav.Link>
                    <Nav.Link href='/admin/Products'><Record></Record> Products </Nav.Link>
                    <Nav.Link href='/admin/productImages'><Record></Record> Product Images </Nav.Link>
                    <Nav.Link href='/admin/productVarients'><Record></Record> Product Varients </Nav.Link>
                    <Nav.Link href='/admin/ProductStocks'><Record></Record> Restock Product </Nav.Link>
                    <Nav.Link href='/admin/Customers'><Record></Record> Customers </Nav.Link>
                    <Nav.Link href='/admin/CustomerDetails'><Record></Record> Customer Details </Nav.Link>
                    <Nav.Link href='/admin/Orders'><Record></Record> Orders </Nav.Link>
                    <Nav.Link href='/admin/OrderDetails'><Record></Record> Order Details </Nav.Link>
                    <Nav.Link href='/admin/Refund'><Record></Record> Refund </Nav.Link>
                </Nav.Item>
            </Nav>
        </Navbar>
    )
}

export default SidebarComponent;