import { useEffect, useState } from "react";
import {  Button, Image, Table } from "react-bootstrap";

import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";
import { CurrencyRupee, PencilSquare, Trash } from "react-bootstrap-icons";

export const MyCartViewer = (props) => {
    const { theam,myCarts, onDeleteHandle, onEdit,buyNow } = props
   
    const getRow = (id, cartDetail) => {
        return (
            <tr key={id}>
                <td>
                    <Image src={"data:image/png;base64," + cartDetail.imageData} className="rounded-circle" width="50" height="50" />
                </td>
                <td>
                    {cartDetail.productTitle}
                </td>
                <td>
                    {cartDetail.productCode}
                </td>
                <td>
                    {cartDetail.price}<CurrencyRupee />
                </td>
                <td>
                    {cartDetail.quantity}
                </td>
                <td>
                    {cartDetail.price * cartDetail.quantity}<CurrencyRupee />
                </td>
                <td>
                    <Button className='ps-2' variant="primary" onClick={() => onEdit(cartDetail)}><PencilSquare /></Button> &nbsp;
                    <Button className='ps-2' variant="danger" onClick={()=>onDeleteHandle(cartDetail.cartDetailId)}><Trash /></Button>
                </td>
            </tr>
        )
    }
    const getFooter = (cartDetails) => {
       const totalPrice= cartDetails.reduce((a,v) =>  a = a + (v.price*v.quantity) , 0 )
       return(
           <tr>
               <td colSpan={5} class="text-end">
                   <b>Total Amount: </b>
               </td>
               <td>
                   <b> {totalPrice}<CurrencyRupee /></b>
               </td>
               <td>
                   <Button className="btn btn-primary" onClick={()=>buyNow(totalPrice)}>Buy Now</Button>
               </td>
           </tr>
       )
    }
    return (
        <Table striped>
            <thead>
                <tr>
                    <th>
                        Image
                    </th>
                    <th>
                        Product Title
                    </th>
                    <th>
                        Product Code
                    </th>
                    <th>
                        Rate
                    </th>
                    <th>
                        Quantity
                    </th>
                    <th>
                        Total Price
                    </th>
                </tr>
            </thead>
            <tbody>
                {myCarts && myCarts[0] ? myCarts[0].cartDetails.map((rowData, rowKey) => getRow(rowKey, rowData)) : ""}
                {myCarts && myCarts[0] ? getFooter(myCarts[0].cartDetails):""}
            </tbody>            
        </Table>
    )
}