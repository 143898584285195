import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"

import { AuthorisedPostDataService } from "../../Reducer/DataService/PostDataService"

export const CustomerEditor = (props) => {
    const { actionType, show, onHide, formData, handleSubmit } = props;
    const [customer, setCustomer] = useState(formData);
    const [isValidated, setIsValidated] = useState(false);    
    const handleFirstName = (e) => {
        customer.firstName = e.target.value;
        CheckValidation();
    }
    const handleLastName = (e) => {
        customer.lastName = e.target.value;
        CheckValidation();
    }
    const handleEmailId = (e) => {
        customer.emailId = e.target.value;
        CheckValidation();
    }

    const handlePhoneNumber = (e) => {
        customer.phoneNumber = e.target.value;
        CheckValidation();
    }

    const handleAlternatePhoneNumber = (e) => {
        customer.alternatePhoneNumber = e.target.value;
        CheckValidation();
    }

    const CheckValidation = () => {
        if (customer.name!="" && customer.phoneNumber!="") {
           return setIsValidated(true);
        }
        return setIsValidated(false);
    }

    const updateChanges = async() => {
        const data = {
            customerId: formData.customerId,
            firstName: customer.firstName ? customer.firstName : formData.firstName,
            lastName: customer.lastName ? customer.lastName : formData.lastName,
            emailId: customer.emailId ? customer.emailId : formData.emailId,
            phoneNumber: customer.phoneNumber ? customer.phoneNumber : formData.phoneNumber,
            alternatePhoneNumber: customer.alternatePhoneNumber ? customer.alternatePhoneNumber : formData.alternatePhoneNumber,
        }
        const response = await AuthorisedPostDataService(data, "UpdateCustomer");
        return await handleSubmit(response);
    }
    const submit = async () => {
        const response = await AuthorisedPostDataService(customer, "AddCustomer");
        return await handleSubmit(response);
    }

    const GetFormTitle = () => {
        if (actionType && actionType === 'UPDATE') {
            return 'Update Category';
        }
        else {
            return 'Add New Category';
        }
    }
    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>
                    {GetFormTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={isValidated} >
                    <Form.Group className="mb-3" controlId="FirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First Name" defaultValue={formData.firstName} onChange={handleFirstName} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="LastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last Name" defaultValue={formData.lastName} onChange={handleLastName} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="EmailId">
                        <Form.Label>Email Id</Form.Label>
                        <Form.Control type="text" placeholder="Email Id" defaultValue={formData.emailId} onChange={handleEmailId} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="PhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" placeholder="Phone Number" defaultValue={formData.phoneNumber} onChange={handlePhoneNumber} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Alternate Phone Number">
                        <Form.Label>Alternate Phone Number</Form.Label>
                        <Form.Control type="text" placeholder="Alternate Phone Number" defaultValue={formData.alternatePhoneNumber} onChange={handleAlternatePhoneNumber} />
                    </Form.Group>                   
                </Form>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                {actionType == "UPDATE" ?
                    <Button variant="primary" onClick={updateChanges} >Update</Button>
                    : <Button variant="primary" disabled={!isValidated} onClick={submit} >Save Changes</Button>}
            </Modal.Footer>
        </Modal>
    )
}