import { useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap"

import { AuthorisedPostDataService } from "../../Reducer/DataService/PostDataService";

export const ShippingAddressEditor = (props) => {
    const { actionType, show, formData, onHide, handleSubmit } = props;
    const [isloading, setIsloading] = useState(false);
    const [shippingAddress, setShippingAddress] = useState(formData);

    const handleContactNumber = (e) => {
        shippingAddress.contactNumber = e.target.value;
    }

    const handleContactPersonName = (e) => {
        shippingAddress.contactPersonName = e.target.value;
    }
    const handleAddressName = (e) => {
        shippingAddress.addressName = e.target.value;
    }

    const handleShippingAddress = (e) => {
        shippingAddress.shippingAddress = e.target.value;
    }

    const handleCity = (e) => {
        shippingAddress.city = e.target.value;
    }

    const handleCountry = (e) => {
        shippingAddress.country = e.target.value;
    }

    const handlePostalCode = (e) => {
        shippingAddress.postalCode = e.target.value;
    }

    const handleIsDefaultAddress = (e) => {
        if (e.target.checked) {
            shippingAddress.isDefaultAddress = true;
        }
        else {
            shippingAddress.isDefaultAddress = false;
        }
    }

    const validateForm = () => {
        if (!shippingAddress
            || shippingAddress.contactNumber == ""
            || shippingAddress.contactPersonName == ""
            || shippingAddress.addressName == ""
            || shippingAddress.isDefaultAddress == ""
            || shippingAddress.shippingAddress == ""
            || shippingAddress.city == ""
            || shippingAddress.country == ""
            || shippingAddress.postalCode == "") {
            return false;
        }
        return true;
    }

    const saveRecord = async (e) => {
        debugger
        if (validateForm()) {
            setIsloading(true);
            const user=JSON.parse(localStorage.getItem("loggedInUser"));
            shippingAddress.userId=user.userId;
            const response = await AuthorisedPostDataService(shippingAddress, "AddUserShipping");
            if (response == 200) {
                setIsloading(false);
                return handleSubmit(response);
            }

        }
    }
    const updateChanges = async () => {
        const data = {
            userShippingAddressId: formData.userShippingAddressId,
            contactNumber: shippingAddress.contactNumber ? shippingAddress.contactNumber : formData.contactNumber,
            contactPersonName: shippingAddress.contactPersonName ? shippingAddress.contactPersonName : formData.contactPersonName,
            addressName: shippingAddress.addressName ? shippingAddress.addressName : formData.addressName,
            isDefaultAddress: shippingAddress.isDefaultAddress ? shippingAddress.isDefaultAddress : formData.isDefaultAddress,
            shippingAddress: shippingAddress.shippingAddress ? shippingAddress.shippingAddress : formData.shippingAddress,
            city: shippingAddress.city ? shippingAddress.city : formData.city,
            country: shippingAddress.country ? shippingAddress.country : formData.country,
            postalCode: shippingAddress.postalCode ? shippingAddress.postalCode : formData.postalCode
        }
        const response = await AuthorisedPostDataService(data, "UpdateUserShipping");
        return handleSubmit(response.status);
    }

    const GetFormTitle = () => {
        if (actionType && actionType === 'UPDATE') {
            return 'Update Shipping Address';
        }
        else {
            return 'Add New Shipping Address';
        }
    }
    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>
                    {GetFormTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="ContactNumber">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control type="number" placeholder="Contact Number" defaultValue={formData.contactNumber} onChange={handleContactNumber} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="ContactPersonName">
                            <Form.Label>Contact Person Name</Form.Label>
                            <Form.Control type="text" placeholder="Contact Person Name" defaultValue={formData.contactPersonName} onChange={handleContactPersonName} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="AddressName">
                            <Form.Label>Address Name</Form.Label>
                            <Form.Control type="text" placeholder="Address Name" defaultValue={formData.addressName} onChange={handleAddressName} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="PostalCode">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="number" placeholder="Postal Code" defaultValue={formData.postalCode} onChange={handlePostalCode} />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="ShippingAddress">
                            <Form.Label>Full Address</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Full Address" defaultValue={formData.shippingAddress} onChange={handleShippingAddress} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="City">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="City" defaultValue={formData.city} onChange={handleCity} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="Country">
                            <Form.Label>Country Name</Form.Label>
                            <Form.Control type="text" placeholder="Country Name" defaultValue={formData.country} onChange={handleCountry} />
                        </Form.Group>
                    </Col>
                   
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="IsDefaultAddress">
                            <Form.Group className="position-relative mb-3">
                                <Form.Check type="switch" label="Is Default Address" defaultValue={formData.isDefaultAddress} onChange={handleIsDefaultAddress} />
                            </Form.Group>
                        </Form.Group>

                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                {isloading ? <Button className='btn btn-primary' > <Spinner animation='grow' /> Saving ...</Button> :
                    actionType == "UPDATE" ? <Button variant="primary" onClick={updateChanges} >Update</Button>
                        : <Button className='btn btn-primary' onClick={saveRecord} > Save</Button>}
            </Modal.Footer>
        </Modal>
    )
}