import React, { useState, useEffect } from 'react';
import {Row,Col,Alert} from 'react-bootstrap';

import SubCategoryBreadCrumb from '../../Component/MasterComponent/SubCategory/SubCategoryBreadCrumb';
import SubCategoryViewer from '../../Component/MasterComponent/SubCategory/SubCategoryViewer';
import SubCategoryEditor from '../../Component/MasterComponent/SubCategory/SubCategoryEditor';
import { AuthorisedGetDataService } from '../../Reducer/DataService/GetDataService';
import { categoryDefaultData } from '../../Constants/DefaultDataConstant';

const SubCategoryPage = () => {
    const [modelShow, setModelShow] = useState(false);
    const [actionType, setActionType] = useState('NEW');
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState(categoryDefaultData);

    useEffect(() => {
        getActiveCategories();
    }, []);

    const newRecord = () => {
        setActionType('NEW');
        setModelShow(true);
        setFormData(categoryDefaultData);
    }

    const onHide = () => {
        setModelShow(false);
    }

    const getActiveCategories = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetAllActiveCategories");
        await getSubCategories();
        setCategories(response);
        setIsLoading(false);
    }
    const getSubCategories = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetAllSubCategories");
        setSubCategories(response);
        setIsLoading(false);
    }
    const onEditRecord = (subCategory) => {
        setFormData({
            subCategoryId: subCategory.subCategoryId,
            category: subCategory.category,
            subCategoryName: subCategory.subCategoryName,
            description: subCategory.description,
            status: subCategory.status
        });
        setActionType("UPDATE");
        setModelShow(true);
    }

    const handleSubmit = async (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
            await getSubCategories();
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }
    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <SubCategoryBreadCrumb />
                {GetAlert()}
                <SubCategoryViewer 
                onNew={newRecord} 
                onEdit={onEditRecord} 
                categories={categories} 
                subCategories={subCategories}
                isLoading={isLoading} />

                <SubCategoryEditor
                    show={modelShow}
                    onHide={() => setModelShow(false)}
                    actionType={actionType}
                    formData={formData}
                    categories={categories}
                    handleSubmit={handleSubmit}
                />
            </Col>
        </Row>
    )
}

export default SubCategoryPage;