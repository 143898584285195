import { Alert, Col, Row } from "react-bootstrap";
import { ProductImageBreadCrumb } from "../Component/ProductImages/ProductImageBreadCrumb";
import { ProductImageLists } from "../Component/ProductImages/ProductImageLists";
import { UploadProductImage } from "../Component/ProductImages/UploadProductImage";
import { useState } from "react";
import { productDefaultGeneralData } from "../Constants/DefaultDataConstant";

export const ProductImages=()=>{
    const [modelShow, setModelShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState(productDefaultGeneralData);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);

    const newRecord = () => {        
        setModelShow(true);
    }

    const onHide = () => {
        setModelShow(false);
    }
    const onProductChange = (productId) => {
        product.productId = productId;
    }
    const handleSubmit = async (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");            
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <ProductImageBreadCrumb />
                {GetAlert()}
                <ProductImageLists isLoading={isLoading} onNew={newRecord} onProductChange={onProductChange} />
                <UploadProductImage show={modelShow} onHide={onHide} handleSubmit={handleSubmit} productId={product.productId} />
            </Col>
        </Row>
    )
}