import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner, Accordion, Button, Form, CardFooter, Alert, } from "react-bootstrap";
import { AuthorisedPostDataService } from "../Reducer/DataService/PostDataService";

export const ChangePassword = (props) => {
  const { theam } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState(true);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const validateForm = () => {
    if (password && newPassword && confirmNewPassword && confirmNewPassword == newPassword) {
      return true;
    }
    return false;
  }
  const handleSubmit = async () => {
    if (validateForm()) {
      const updatePassword = {
        oldPassword: password,
        newPassword: newPassword,
        confirmPassword: confirmNewPassword
      }

      const response = await AuthorisedPostDataService(updatePassword, "ChangePassword");
      if (response == "200") {
        setVariant('success')
        setMessage("Password changed successfully.");

      }
      else {
        setVariant('danger')
        setMessage(response.message);
      }
    }
  }

  const GetAlert = () => {
    if (showAlert) {
      return (
        <Alert key={variant} variant={variant}>
          {message}
        </Alert>
      )
    }
    return "";
  }

  return (
    <Container>
      <Row className="justify-content-center" style={{ marginTop: 150, marginBottom: 70 }}>
        <Col className="col-6 justify-content-center">
          {GetAlert()}
          <Card>
            <CardHeader>
              <h3 className="text-center mt-2">Update Password</h3>
            </CardHeader>
            <CardBody>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control type="password" placeholder="Old Password" value={password} onChange={e => setPassword(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control type="password" placeholder="New Password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control type="password" placeholder="Confirm Password" value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} />
                </Form.Group>
              </Form>
            </CardBody>
            <CardFooter className="text-center">
              {isLoading ?
                <Button className="btn btn-primary w-50" type="submit" > <Spinner animation='grow' /> Loaging... </Button>
                : <Button variant={theam} className="w-50" onClick={handleSubmit}> Update Password  </Button>}

            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

