import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Spinner } from "react-bootstrap";


import { MyOrderViewer } from "../Component/MyOrder/MyOrderViewer"
import { AuthorisedGetDataService } from "../Reducer/DataService/GetDataService";
import { DeleteAlert } from "../Component/Common/DeleteAlert";
import { AuthorisedDeleteDataService } from "../Reducer/DataService/PostDataService";
import { Payment } from "../Component/Payment/Payment";


export const MyOrderPage=(props)=>{

    const { theam } = props
    const [actionType, setActionType] = useState('NEW');
    const [isLoading, setIsLoading] = useState(true);
    const [modelShow, setModelShow] = useState(false);
    const [paymentShow, setPaymentShow] = useState(false);
    const [deleteAlertShow, setDeleteAlertShow] = useState(false);
    const [myOrders, setMyOrders] = useState([]);
    const [deleteRecordId, setDeleteRecordId] = useState(0);
const[totalAmount,setTotalAmount]=useState(0);
    useEffect(() => {
        getMyOrderDetails();
    }, []);

    const getMyOrderDetails = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetMyOrder");
        debugger
        setMyOrders(response);
        setIsLoading(false);
    }
    
    const onHide = () => {
        setModelShow(false);
    }
    const handleSubmit = (response) => {
        if (response == "200") {
            setModelShow(false);
            getMyOrderDetails();
        }
    }
    const onDeleteCancel = () => {
        setDeleteAlertShow(false);
    }
    const onDeleteHandle = (cartDetailId) => {
        setDeleteAlertShow(true);
        setDeleteRecordId(cartDetailId);
    }

    const onDeleteConfirm = async () => {
        const response = await AuthorisedDeleteDataService(`DeleteFromOrder/${deleteRecordId}`);
        if (response == "200") {
            setDeleteAlertShow(false);
            getMyOrderDetails();
        }
    }
    const showPayment = (totalAmount) => {
        setTotalAmount(totalAmount);
        setPaymentShow(true);
    }

    const onPaymentHide = () => {
        setPaymentShow(false);
    }

    return (
        <Container style={{ marginTop: 150, marginBottom: 70 }}>
            {isLoading ?
                <Row className='pe-0' style={{ flex: 1, marginTop: 340, justifyContent: 'center', alignContent: 'center' }} >
                    <Col md={2}>
                        <Spinner animation="grow" variant={theam} size="sm" style={{ flex: 1, alignSelf: 'center' }} />
                        <Spinner animation="grow" variant={theam} style={{ flex: 1, alignSelf: 'center' }} />
                    </Col>
                </Row>
                : 
            <Card>
                <CardHeader>
                    <CardTitle>Order Details</CardTitle>
                </CardHeader>
                <CardBody>
                    <MyOrderViewer myOrders={myOrders}  onDeleteHandle={onDeleteHandle} />
                    <DeleteAlert show={deleteAlertShow} onHide={onDeleteCancel} onConfirm={onDeleteConfirm}/>
                </CardBody>
            </Card>}
            <Payment show={paymentShow} onHide={onPaymentHide} totalAmount={totalAmount} />
        </Container>
    )
}
