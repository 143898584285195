import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "react-bootstrap"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useEffect, useState } from "react";

import { ProductTiles } from "../Component/Home/ProductTiles";
import { GetDataService } from "../Reducer/DataService/GetDataService";
import { AddToCartModal } from "../Component/Cart/AddToCartModal";
import { cartDefaultData } from "../Constants/DefaultDataConstant";
import { HomeBanner } from "../Component/Home/HomeBanner";
import { UserAccountComponent } from "../Component/Common/UserAccountComponent";

export const HomePage = (props) => {
    const { theam } = props
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("loggedInUser")) || null);
    const [productModal, setProductModal] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modelShow, setModelShow] = useState(false);   
    const [cartFormData, setCartFormData] = useState(cartDefaultData);
    const [loginShow, setLoginShow] = useState(false);    
    const responsive = {
        superLargeDesktop: {

            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    const onLogin=()=>{
        setLoginShow(true);
      }
      const onHide=()=>{
        setLoginShow(false);
      }
    const getProducts = async () => {
        setIsLoading(true);
        var response = await GetDataService("GetAllDraftProducts/1/10");
        setProductModal(response);
        setIsLoading(false);
    }
    
    const onAddCart = (product, price) => {
            cartFormData.productId = product.productId;
            cartFormData.quantity = 1;
            cartFormData.price = price;
            setModelShow(true);
    }
    
    const loginSuccessFull = () => {
        setUser(JSON.parse(localStorage.getItem("loggedInUser")));
        setLoginShow(false);
      }
    const handleSubmit=(response)=>{
        if (response == "200") {
            setModelShow(false);
            window.location.reload(false);
        }
    }
    const handleLogin=(response)=>{
        if (response.isSuccess) {
            onHide();
            window.location.reload(false);
        }
    }
    const getRow = (id, product) => {
        return (
            <div>
                <ProductTiles product={product} onAddCart={onAddCart} onLogin={onLogin} />
            </div>
        )
    }
    return (
        <Container>            
            {isLoading ?
                <Row className='pe-0' style={{ flex: 1, marginTop: 340, justifyContent: 'center', alignContent: 'center' }} >
                    <Col md={2}>
                        <Spinner animation="grow" variant={theam} size="sm" style={{ flex: 1, alignSelf: 'center' }} />
                        <Spinner animation="grow" variant={theam} style={{ flex: 1, alignSelf: 'center' }} />
                    </Col>
                </Row>
                : productModal && productModal.products ? [
                    <Row className='pe-0' style={{ marginTop: 108 }} >
                        <Col md={12}>
                            <HomeBanner theam={theam} />
                        </Col>
                    </Row>,
                    <Row className='pe-0' style={{ marginTop: 10 }} >
                        <Col md={12}>
                            <Card >
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <Card.Title> Best Offer
                                                <a href="/home/productGrid"  variant="outline-secondary" className='btn float-end'>See More...</a>
                                            </Card.Title>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Carousel responsive={responsive}>
                                            {productModal && productModal.products ? productModal.products.map((rowData, rowKey) => getRow(rowKey, rowData)):""}
                                        </Carousel>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>,
                    <Row className='pe-0' style={{ marginTop: 10,marginBottom:80 }} >
                        <Col md={12}>
                            <Card >
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <Card.Title> Wish List
                                            <a href="/home/productGrid"  variant="outline-secondary" className='btn float-end'>See More...</a>
                                            </Card.Title>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Carousel responsive={responsive}>
                                            {productModal && productModal.products ? productModal.products.map((rowData, rowKey) => getRow(rowKey, rowData)):""}
                                        </Carousel>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>] : "NO Data found"}
            <AddToCartModal show={modelShow} onHide={onHide} formData={cartFormData} handleSubmit={handleSubmit} />
            <UserAccountComponent theam={theam} onLogin={onLogin} onHide={onHide} loginShow={loginShow} loginSuccessFull={loginSuccessFull} />
        </Container>
    )
}