import { Button, Card, Col, Row, Table } from "react-bootstrap"
import { PencilSquare, PlusSquare, Upload } from "react-bootstrap-icons"
import { DefaultDateFormat } from "../../Utility"
import { PagingComponent } from "../Common/PagingComponent";

export const ProductViewer = (props) => {
    const { onNew, onEdit, onUpload, isLoading, products, totalRecords, pageSize,currentPage,onPageClick } = props;

    const getRow = (id, product) => {
        return (
            <tr key={id}>
                <td>
                    {product.productId}
                </td>
                <td>
                    {product.categoryName}
                </td>
                <td>
                    {product.subCategoryName}
                </td>
                <td>
                    {product.vendorName}
                </td>
                <td>
                    {product.productTitle}
                </td>
                <td>
                    {product.tagTitle}
                </td>
                <td>
                    {product.productCode}
                </td>
                <td>
                    {product.measuringUnitName}
                </td>
                <td>
                    {product.price}
                </td>
                <td>
                    {product.status == 0 ? "DRAFT" : product.status == 1 ? "APPROVED" : "REJECTED"}
                </td>
                <td>
                    {DefaultDateFormat(product.createdDate)}
                </td>
                <td>
                    <Button className='ps-2' variant="primary" onClick={() => onEdit(product)}><PencilSquare /></Button> &nbsp;
                    <Button className='ps-2' variant="primary" onClick={() => onUpload(product.productId)}><Upload /></Button>
                </td>
            </tr>
        )
    }

    return (isLoading ? <h3>loading...</h3> : (
        <Card>
            <Card.Header>
                <Row >
                    <Col>
                        <Card.Title>Products Information
                            <a className='btn btn-primary float-end' variant="primary" onClick={onNew}><PlusSquare /> Add New Product</a>
                        </Card.Title>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    Id
                                </th>
                                <th>
                                    Category
                                </th>
                                <th>
                                    SubCategory
                                </th>
                                <th>
                                    Vendor
                                </th>
                                <th>
                                    Product Title
                                </th>
                                <th>
                                    Tag
                                </th>
                                <th>
                                    Product Code
                                </th>                                
                                <th>
                                    Unit
                                </th> 
                                <th>
                                    Price
                                </th>                             
                                <th>
                                    Status
                                </th>
                                <th>
                                    Created Date
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            {products?products.map((rowData, rowKey) => getRow(rowKey, rowData)):""}
                        </tbody>
                    </Table>
                  <PagingComponent totalRecords={totalRecords} pageSize={pageSize} currentPage={currentPage} onPageClickHandle={onPageClick} />
                </Col>
            </Card.Body>
        </Card>
    ));
}