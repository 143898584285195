import { PencilSquare, PlusSquare, Trash } from 'react-bootstrap-icons';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { DefaultDateFormat } from '../../../Utility';
import { useState } from 'react';

const SubCategoryViewer = (props) => {
    const { onNew, onEdit, isLoading, subCategories, categories } = props;

    const onDeleteHandle = (subCategoryId) => {

    }

    const getRow = (id, subCategory) => {
        return (
            <tr key={id}>
                <td>
                    {subCategory.subCategoryId}
                </td>
                <td>
                    {subCategory.categoryName}
                </td>
                <td>
                    {subCategory.subCategoryName}
                </td>
                <td>
                    {subCategory.description}
                </td>
                <td>
                    {subCategory.status == 1 ? "Active" : "InActive"}
                </td>
                <td>
                    {DefaultDateFormat(subCategory.createdDate)}
                </td>
                <td>
                    <Button className='ps-2' variant="primary" onClick={() => onEdit(subCategory)}><PencilSquare /></Button> &nbsp;
                </td>
            </tr>
        )
    }
    return (isLoading ? <h3>loading...</h3> : (
        <Card>
            <Card.Header>
                <Row >
                    <Col>
                        <Card.Title>Sub Category Information
                            <Button className='float-end' variant="primary" onClick={onNew}><PlusSquare /> New</Button>
                        </Card.Title>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    Id
                                </th>
                                <th>
                                    Category Name
                                </th>
                                <th>
                                    Sub Category Name
                                </th>
                                <th>
                                    Description
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Created Date
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            {subCategories.map((rowData, rowKey) => getRow(rowKey, rowData))}
                        </tbody>
                    </Table>
                </Col>
            </Card.Body>
        </Card>
    ));
}

export default SubCategoryViewer;