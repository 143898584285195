import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { BoxArrowRight, PersonCircle, Gear, QuestionCircle, Cart, Basket, BrightnessHigh, Brush } from 'react-bootstrap-icons';
import { DropdownHeader } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import ProfileImage from '../../Images/ProfileImage.jpg';

const ProfileComponent = (props) => {
    const { onLogout, user, theam } = props

    const getTitle = () => {
        return (
            <Button variant={theam}>
                <h6><Image src={ProfileImage} alt={user.firstName} className="rounded-circle" width="30" height="30" /> {user.firstName} {user.lastName}</h6>
            </Button>
        )
    }

    const getAdmin=()=>{
        const roles = user.roles.filter(role => role == "Admin" || role=="SuperAdmin");
        if (roles && roles.length > 0) {
            return (
                <NavDropdown.Item href="/Admin"> <Gear /> Go To Admin Part </NavDropdown.Item>
            );
        }
        return "";
      }
    return (
        <NavDropdown title={getTitle()} style={{zIndex:"1035"}} >
            <DropdownHeader href="#action3">
                <h6>{user.firstName} {user.lastName}</h6>
                <span>{user.role}</span>
            </DropdownHeader>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/home/ProfilePage"><PersonCircle /> My Profile</NavDropdown.Item>
            {getAdmin()}
            <NavDropdown.Item href="/home/CartDetails"><Cart /> My Cart</NavDropdown.Item>
            <NavDropdown.Item href="/home/OrderPage"><Basket /> My Order</NavDropdown.Item>
            <NavDropdown.Item href="#action3"><QuestionCircle /> Need Help?</NavDropdown.Item>
            <NavDropdown.Item href="/home/MaterialReturnPolicy"><BrightnessHigh /> Material Return Policy</NavDropdown.Item>
            <NavDropdown.Item href="/home/ChangePassword"><Brush /> Change Password</NavDropdown.Item>
            <NavDropdown.Item href="/home/Refund"><Basket /> Refund</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={onLogout} ><BoxArrowRight /> Sign Out</NavDropdown.Item>
        </NavDropdown>
    )
}

export default ProfileComponent;