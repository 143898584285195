import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "react-bootstrap";

import { GetDataService } from "../Reducer/DataService/GetDataService";
import { ProductTiles } from "../Component/Home/ProductTiles";
import { cartDefaultData } from "../Constants/DefaultDataConstant";
import { AddToCartModal } from "../Component/Cart/AddToCartModal";
import LoginPage from "./LoginPage";
import Register from "./Register";

export const ProductGrid = (props) => {
    const { theam } = props
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [modelShow, setModelShow] = useState(false);
    const [cartFormData, setCartFormData] = useState(cartDefaultData);
    const [loginShow, setLoginShow] = useState(false);
    const [signupShow, setSignupShow] = useState(false);
    useEffect(() => {
            getProducts(currentPage);
    }, []);
   
    const getProducts = async () => {
        setIsLoading(true);
        var response = await GetDataService("GetAllDraftProducts/1/10");
        if (response?.products.length) {
            setProducts(response?.products);
        }
        setIsLoading(false);
    }
    const onAddCart = (product, price) => {
        cartFormData.productId = product.productId;
        cartFormData.quantity = 1;
        cartFormData.price = price;
        setModelShow(true);
    }
    const showLogin = () => {
        setLoginShow(true);
        setSignupShow(false);
    }
    const onHide = () => {
        setModelShow(false);
    }
    const onLoginHide = () => {
        setLoginShow(false);
    }

    const onSignup = () => {
        setSignupShow(true);
        setLoginShow(false);
    }

    const onSignupHide = () => {
        setSignupShow(false);
    }
    const getRow = (id, product) => {
        return (
            <ProductTiles product={product} onAddCart={onAddCart} onLogin={showLogin} />
        )
    }
    const handleSubmit=(response)=>{
        if (response == "200") {
            setModelShow(false);
        }
    }
    return (
        <Container>
            {isLoading ?
                <Row className='pe-0' style={{ flex: 1, marginTop: 340, justifyContent: 'center', alignContent: 'center' }} >
                    <Col md={2}>
                        <Spinner animation="grow" variant={theam} size="sm" style={{ flex: 1, alignSelf: 'center' }} />
                        <Spinner animation="grow" variant={theam} style={{ flex: 1, alignSelf: 'center' }} />
                    </Col>
                </Row>
                : products ?
                    <Row className='pe-0' style={{ marginTop: 130, marginBottom: 70 }} >
                        <Col md={12}>
                            <Card >
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <Card.Title> All Products </Card.Title>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {products ? products.map((rowData, rowKey) => getRow(rowKey, rowData)) : ""}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> : "NO Data found"}
            <AddToCartModal show={modelShow} onHide={onHide} formData={cartFormData} handleSubmit={handleSubmit} />
            <LoginPage show={loginShow} onHide={onLoginHide} onSignup={onSignup} theam={theam} />
            <Register show={signupShow} onHide={onSignupHide} onLogin={showLogin} theam={theam} />
        </Container>
    )
}