import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { AuthorisedPostDataService } from "../../Reducer/DataService/PostDataService";
import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";

export const ProductStockEditor=(props)=>{
    const { show, onHide, actionType, handleSubmit, formData } = props;
    const [isloading, setIsloading] = useState(false);
    const [productStock, setProductStock] = useState(formData);

    const validateForm = () => {
        if (productStock.productId && productStock.restockPrice && productStock.restockQuantity) {
            return true;
        }
        return false;
    }
    const saveRecord = async (e) => {
        setIsloading(true);
        if (validateForm()) {
            const response = await AuthorisedPostDataService(productStock, "AddProductStock");
            props.handleSubmit(response);
            handleSubmit(response);
        }
        setIsloading(false);
    }

    const onRestockPriceChange = (e) => {
        productStock.restockPrice = e.target.value;
    }

    const onQuantityChange = (e) => {
        productStock.restockQuantity = e.target.value;
    }
    const getFormTitle = () => {
        if (actionType && actionType === 'UPDATE') {
            return 'Update Category';
        }
        else {
            return 'Restock Product';
        }
    }
    const getOption = (row) => {
        return (
            <option value={row.varientId}>{row.varientName}</option>
        )
    }

    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>
                    {getFormTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                    <Form.Group className="mb-3" controlId="Quantity">
                            <Form.Label>Quantity </Form.Label>
                            <Form.Control type="text" placeholder="Quantity" defaultValue={formData.restockQuantity} onChange={onQuantityChange} />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="Price">
                            <Form.Label>Price </Form.Label>
                            <Form.Control type="text" placeholder="Price" defaultValue={formData.restockPrice} onChange={onRestockPriceChange} />
                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                {isloading ? <Button className='btn btn-primary' > <Spinner animation='grow' /> Saving ...</Button> :
                    <Button className='btn btn-primary' onClick={saveRecord} > Save</Button>}
            </Modal.Footer>
        </Modal>
    )
}