import { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";

import { productStockDefaultData } from "../Constants/DefaultDataConstant";
import { ProductRestockList } from "../Component/ProductStock/ProductRestockList";
import { ProductStockBreadCrumb } from "../Component/ProductStock/ProductStockBreadCrumb";
import { ProductStockEditor } from "../Component/ProductStock/ProductStockEditor";
import { AuthorisedGetDataService } from "../Reducer/DataService/GetDataService";

export const ProductStocks = () => {
    const [modelShow, setModelShow] = useState(false);
    const [actionType, setActionType] = useState('NEW');

    const [isLoading, setIsLoading] = useState(false);
    const [productStock, setProductStock] = useState(productStockDefaultData);
    const [stockHistory, setStockHistory] = useState([]);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getAllProducts();
    }, []);

    const getAllProducts = async () => {
        var response = await AuthorisedGetDataService("GetProductsForDropDown");
        setProducts(response);
    }
    const newRecord = () => {
        setModelShow(true);
        setProductStock(productStockDefaultData);
        setActionType('NEW');
    }

    const onHide = () => {
        setModelShow(false);
    }

    const onEditRecord = (productStock) => {
        setProductStock({
            productStockId: productStock.productStockId,
            productId: productStock.productId,
            restockQuantity: productStock.restockQuantity,
            restockPrice: productStock.restockPrice
        });
        setActionType("UPDATE");
        setModelShow(true);
    }
    const onProductChange = async (productId) => {
        productStock.productId = productId;
        await getRestockHistory(productId);
    }
    const getRestockHistory = async (productId) => {
        var response = await AuthorisedGetDataService("GetProductStockById/" + productId);
        setStockHistory(response);
    }
    const handleSubmit = async (data) => {
        debugger
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
            await getRestockHistory(productStock.productId);
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <ProductStockBreadCrumb />
                {GetAlert()}
                <ProductRestockList isLoading={isLoading} onNew={newRecord} onEdit={onEditRecord} onProductChange={onProductChange} products={products} stockHistory={stockHistory} />
                <ProductStockEditor show={modelShow} onHide={onHide} actionType={actionType} handleSubmit={handleSubmit} formData={productStock} />
            </Col>
        </Row>
    )
}