import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoginService } from "../../Reducer/DataService/UserDataService";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(localStorage.getItem("loggedInUser") || null);
    const navigate = useNavigate();

    const loginAction = async (response) => {
        try {
            
            if(response.isSuccess){
                console.log(response);
                setUser(response.data);
                localStorage.setItem("loggedInUser", JSON.stringify(response.data));
                const roles = response.data.roles.filter(role => (role == "Admin") || role=="SuperAdmin");
                if (roles && roles.length > 0) {
                    return navigate("/admin");
                }
                return navigate("/home");
            }
        }
        catch (err) {
            console.log(err);
            navigate("/");
        }
    }

    const logoutAction = () => {
        setUser(null);
        localStorage.removeItem("loggedInUser");
        navigate("/");
    }

    return (
        <AuthContext.Provider value={{ user, loginAction, logoutAction }}>
            {children}
        </AuthContext.Provider>
    );
}
export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};