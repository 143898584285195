import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";

export const DeleteAlert = (props) => {
    const { show, onHide, onConfirm } = props;
    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <ModalHeader closeButton>
                <h5> <ExclamationTriangle color="red" /> Delete Record</h5>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12}>
                        <h4>Are you sure to delete this record?</h4> 
                        <p><b>Confirm</b> to delete else <b>Cancel</b> </p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-primary" onClick={onConfirm} >Confirm</Button>&nbsp;
                <Button className="btn btn-secondary" onClick={onHide} >Cancel</Button>
            </ModalFooter>
        </Modal>
    )

}