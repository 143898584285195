import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { PencilSquare, PlusSquare } from "react-bootstrap-icons";

import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";
import { DefaultDateFormat } from "../../Utility";

export const ProductRestockList = (props) => {
    const { onNew, onEdit, isLoading, product, onProductChange, products,stockHistory } = props;

    const [productStocks, setProductStocks] = useState(stockHistory);


    const getProductStocks = async (productId) => {
       
        onProductChange(productId);
    }

    const handleChange = async (e) => {
        getProductStocks(e.target.value);
    }

    const getProductOption = (row) => {
        return (
            <option value={row.productId}>{row.productTitle} | {row.productCode}</option>
        )
    }

    const getRow = (id, productStock) => {
        return (
            <tr key={id}>
                <td>
                    {productStock.productStockId}
                </td>
                <td>
                    {productStock.restockQuantity}
                </td>
                <td>
                    {productStock.restockPrice}
                </td>
                <td>
                    {DefaultDateFormat(productStock.restockDate)}
                </td>
                
            </tr>
        )
    }

    return (isLoading ? <h3>loading...</h3> : (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <Card.Title>Products Stock History
                            <a className='btn btn-primary float-end' variant="primary" onClick={onNew}><PlusSquare /> Restock </a>
                        </Card.Title>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Title>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="Category">
                                <Form.Label>Select Product </Form.Label>
                                <Form.Select onChange={handleChange} defaultValue={product ? product.productId : "Select"}>
                                    <option>Select</option>
                                    {products ? products.map((row) => getProductOption(row)) : ""}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <hr />
                    </Row>
                </Card.Title>
                <Row>
                    <Col md={12}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>
                                        Id
                                    </th>
                                    <th>
                                        Quantity
                                    </th>
                                    <th>
                                        Price
                                    </th>
                                    <th>
                                        Restock Date
                                    </th>
                                  
                                </tr>
                            </thead>
                            <tbody>
                                {productStocks?productStocks.map((rowData, rowKey) => getRow(rowKey, rowData)):""}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    ));
}