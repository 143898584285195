import { Card, CardBody, CardHeader, Col, FormCheck, Row, Image, Container, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';

import { SignupService } from '../Reducer/DataService/UserDataService';

function Register(props) {
    const { show, onHide, onLogin, theam } = props;
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(true);
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        confirmPassword: "",
        email: "",
        isTermCondition: false
    });
    const handleSubmit = async e => {
        e.preventDefault();
        if (ValidateForm()) {
            var response = await SignupService(userData);
            console.log(response);
            if (response.isSuccess) {
                setVariant('success')
                setMessage(response.message);
                setShowAlert(true);           
                onHide()
            }
            else {
                setVariant('danger')
                setMessage(response.message);
                setShowAlert(true);
            }
            
            return;
        }
        return false;
    }

    const handleFirstName = (e) => {
        const data = userData;
        data.firstName = e.target.value;
        setUserData(data);
        ValidateForm();
    }
    const handleLastName = (e) => {
        const data = userData;
        data.lastName = e.target.value;
        setUserData(data);
        ValidateForm();
    }
    const handleEmail = (e) => {
        const data = userData;
        data.email = e.target.value;
        setUserData(data);
        ValidateForm();
    }
    const handleUserName = (e) => {
        const data = userData;
        data.userName = e.target.value;
        setUserData(data);
        ValidateForm();
    }
    const handlePassword = (e) => {
        const data = userData;
        data.password = e.target.value;
        setUserData(data);
        ValidateForm();
    }
    const handleConfirmPassword = (e) => {
        const data = userData;
        data.confirmPassword = e.target.value;
        setUserData(data);
        ValidateForm();
    }
    const handleTermCondition = (e) => {
        const data = userData;
        data.isTermCondition = e.target.checked;
        setUserData(data);
        ValidateForm();
    }

    const ValidateForm = () => {
        const data = userData;
        if (!data.firstName || !data.lastName || !data.userName || !data.password || !data.email || !data.confirmPassword
            || data.confirmPassword != data.password || !data.isTermCondition) {
            setEnableSubmit(false);
            return false;
        }
        else {
            setEnableSubmit(true);
            return true;
        }

    }
    const GetAlert = () => {
        if (show) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title className="justify-content-center">
                    <h4 className='float-center'> Create new account  </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        {GetAlert()}
                        <Card className="mb-6">
                            <CardBody>
                                <form className="row g-3 needs-validation" novalidate>
                                    <div className="col-6">
                                        <label for="yourName" className="form-label">First Name</label>
                                        <input type="text" name="name" className="form-control" id="yourName" required onChange={handleFirstName} />
                                        <div className="invalid-feedback">Please, enter your first name!</div>
                                    </div>
                                    <div className="col-6">
                                        <label for="yourName" className="form-label">Last Name</label>
                                        <input type="text" name="name" className="form-control" id="yourName" required onChange={handleLastName} />
                                        <div className="invalid-feedback">Please, enter your last name!</div>
                                    </div>
                                    <div className="col-6">
                                        <label for="yourEmail" className="form-label">Email</label>
                                        <input type="email" name="email" className="form-control" id="yourEmail" required onChange={handleEmail} />
                                        <div className="invalid-feedback">Please enter a valid Email adddress!</div>
                                    </div>
                                    <div className="col-6">
                                        <label for="yourUsername" className="form-label">Username</label>
                                        <div className="input-group has-validation">
                                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                                            <input type="text" name="username" className="form-control" id="yourUsername" required onChange={handleUserName} />
                                            <div className="invalid-feedback">Please choose a username.</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <label for="yourPassword" className="form-label">Password</label>
                                        <input type="password" name="password" className="form-control" id="yourPassword" required onChange={handlePassword} />
                                        <div className="invalid-feedback">Please enter your password!</div>
                                    </div>
                                    <div className="col-6">
                                        <label for="yourPassword" className="form-label">Confirm Password</label>
                                        <input type="password" name="password" className="form-control" id="yourPassword" required onChange={handleConfirmPassword} />
                                        <div className="invalid-feedback">Please confirm your password!</div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-check">
                                            <FormCheck name="terms" type="checkbox" id="acceptTerms" required onChange={handleTermCondition} />
                                            <label className="form-check-label" for="acceptTerms">I agree and accept the <a href="#">terms and conditions</a></label>
                                            <div className="invalid-feedback">You must agree before submitting.</div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button disabled={!enableSubmit} className="btn btn-primary w-50" type="submit" onClick={handleSubmit}>Create Account</button>
                                    </div>

                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer >
                <div className="col-12 text-center">
                    <a onClick={onLogin}> <p className="small mb-0">Already have an account? Log in</p></a>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default Register;