import { useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { ProductVarientList } from "../Component/ProductVarients/ProductVarientList";
import { ProductVarientBreadCrumb } from "../Component/ProductVarients/ProductVarientBreadCrumb";
import { ProductVarientEditor } from "../Component/ProductVarients/ProductVarientEditor";
import { productVarientDefaultData } from "../Constants/DefaultDataConstant";

export const ProductVarients = () => {
    const [modelShow, setModelShow] = useState(false);
    const [actionType, setActionType] = useState('NEW');
    const [isLoading, setIsLoading] = useState(false);
    const [productVarient, setProductVarient] = useState(productVarientDefaultData);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);

    const newRecord = () => {
        if (productVarient.productId > 0) {
            setModelShow(true);
            setProductVarient(productVarientDefaultData);
            setActionType('NEW');
        }
    }

    const onHide = () => {
        setModelShow(false);
    }

    const onEditRecord = (productVarient) => {
        setProductVarient({
            productVarientId: productVarient.productVarientId,
            productId: productVarient.productId,
            varientId: productVarient.varientId,
            varientValue: productVarient.varientValue
        });
        setActionType("UPDATE");
        setModelShow(true);
    }
    const onProductChange = (productId) => {
        debugger
        productVarient.productId = productId;
    }
    const handleSubmit = async (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <ProductVarientBreadCrumb />
                {GetAlert()}
                <ProductVarientList isLoading={isLoading} onNew={newRecord} onEdit={onEditRecord} onProductChange={onProductChange} />
                <ProductVarientEditor show={modelShow} onHide={onHide} actionType={actionType} handleSubmit={handleSubmit} formData={productVarient} />
            </Col>
        </Row>
    )
}