import { useEffect, useState } from "react";
import {  Button, Image, Table } from "react-bootstrap";

import { AuthorisedGetDataService } from "../../Reducer/DataService/GetDataService";
import { CurrencyRupee, PencilSquare, Trash } from "react-bootstrap-icons";

export const MyOrderViewer = (props) => {
    const { theam,myOrders, onDeleteHandle, onEdit } = props

    const getRow = (id, orderDetail) => {
        return (
            <tr key={id}>
                <td>
                    <Image src={"data:image/png;base64," + orderDetail.imageData} className="rounded-circle" width="50" height="50" />
                </td>
                <td>
                    {orderDetail.productTitle}
                </td>
                <td>
                    {orderDetail.productCode}
                </td>
                <td>
                    {orderDetail.price}<CurrencyRupee />
                </td>
                <td>
                    {orderDetail.quantity}
                </td>
                <td>
                    {orderDetail.price * orderDetail.quantity}<CurrencyRupee />
                </td>
                <td>
                    <Button className='ps-2' variant="primary" onClick={() => onEdit(orderDetail)}><PencilSquare /></Button> &nbsp;
                    <Button className='ps-2' variant="danger" onClick={()=>onDeleteHandle(orderDetail.orderDetailId)}><Trash /></Button>
                </td>
            </tr>
        )
    }

    return (
        <Table striped>
            <thead>
                <tr>
                <th>
                        Order Id
                    </th>
                    <th>
                        Order Date
                    </th>
                    <th>
                        Image
                    </th>
                    <th>
                        Product Title
                    </th>
                    <th>
                        Product Code
                    </th>
                    <th>
                        Rate
                    </th>
                    <th>
                        Quantity
                    </th>
                    <th>
                        Total Price
                    </th>
                </tr>
            </thead>
            {/* <tbody>
                {myCarts && myCarts[0] ? myCarts[0].cartDetails.map((rowData, rowKey) => getRow(rowKey, rowData)) : ""}
                {myCarts && myCarts[0] ? getFooter(myCarts[0].cartDetails):""}
            </tbody>             */}
        </Table>
    )



}