import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const FooterComponent=(props)=>{
    const {theam}=props
    return (
        <Navbar fixed="bottom" bg={theam} variant={theam}>
            <Nav className="flex-grow-1">
                <Nav.Link className="justify-content-start pe-5">
                    &copy; Copyright <strong><span><a href="#login">ABC Pvt Ltd</a></span></strong>. All Rights Reserved
                </Nav.Link>
            </Nav >
            <Nav className="justify-content-end flex-grow-1">                
                <Nav.Link className="pe-5">
                    Designed by <strong><span><a href="https://lkraj.com" target="_blank" > Lkraj.com</a>.</span></strong>
                </Nav.Link>
            </Nav >
        </Navbar>
    );
}

export default FooterComponent;