import { Card, CardBody, CardTitle, Col, Form, Row, Table } from "react-bootstrap";

export const RefundLists = (props) =>{

   return(
       <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <Card.Title>Refund Information</Card.Title>           
                    </Col>
                </Row>
            </Card.Header>
            <CardBody>
            <CardTitle>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="Category">
                                <Form.Label>Refund Status </Form.Label>
                                <Form.Select >
                                    <option>Select</option>
                                    <option value={3}>Refund Requested</option>
                                    <option value={4}>Refund Completed</option>
                                    <option value={5}>Refund Cancelled</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <hr />
                    </Row>
                </CardTitle>
                <Row>
                    <Col md={12}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>
                                      RefundId
                                    </th>
                                    <th>
                                      OrderId 
                                    </th>
                                    <th>
                                      CustomerId
                                    </th>
                                    <th>
                                       PaymentStatus
                                    </th>
                                    <th>
                                       Price  
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
       </Card>
   )
}