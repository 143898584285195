import { Carousel, Image } from 'react-bootstrap'

export const ProductImages = (props) => {
    const { productImages } = props;
    
    const getRow = (id, productImage) => {
        return (
            <Carousel.Item interval={2000}>
                <Image width={500} src={"data:image/png;base64," + productImage.fileData} />
            </Carousel.Item>
        )
    }

    return (
        <Carousel>
            {productImages ? productImages.map((rowData, rowKey) => getRow(rowKey, rowData)):""}
        </Carousel>
    )
}