import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

import VendorBreadCrumb from '../../Component/MasterComponent/Vendor/VendorBreadCrumb';
import VendorEditor from '../../Component/MasterComponent/Vendor/VendorEditor';
import VendorViewer from '../../Component/MasterComponent/Vendor/VendorViewer';
import { AuthorisedGetDataService } from '../../Reducer/DataService/GetDataService';
import { varientDefaultData } from '../../Constants/DefaultDataConstant';

const VendorPage = () => {

    const [modelShow, setModelShow] = useState(false);
    const [actionType, setActionType] = useState('NEW');
    const [vendors, setVendors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(true);
    const [variant, setVariant] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState(varientDefaultData);

    useEffect(() => {
        getAllVendors();
    }, []);

    const getAllVendors = async () => {
        setIsLoading(true);
        var response = await AuthorisedGetDataService("GetAllVendors");
        setVendors(response);
        setIsLoading(false);
    }


    const newRecord = () => {
        setActionType('NEW');
        setModelShow(true);
        setFormData(varientDefaultData);
    }

    const onHide = () => {
        setModelShow(false);
    }
    const onEditRecord = (vendor) => {
        setFormData({
            vendorId: vendor.vendorId,
            vendorName: vendor.vendorName,
            description: vendor.description,
            gstNumber: vendor.gstNumber,
            address: vendor.address,
            status: vendor.status
        });
        setActionType("UPDATE");
        setModelShow(true);
    }
    const handleSubmit = async (data) => {
        if (data == "200") {
            setMessage("Record save successfully.");
            setVariant("success");
            await getAllVendors();
        }
        else {
            setMessage("Record not saved.");
            setVariant("danger");
        }
        setShowAlert(true);
        onHide();
        setTimeout(() => {
            setShowAlert(false);
        }, 2500)
    }

    const GetAlert = () => {
        if (showAlert) {
            return (
                <Alert key={variant} variant={variant}>
                    {message}
                </Alert>
            )
        }
        return "";
    }
    return (
        <Row>
            <Col md={12}>
                <VendorBreadCrumb />
                {GetAlert()}
                <VendorViewer onNew={newRecord} onEdit={onEditRecord} vendors={vendors} isLoading={isLoading} />
                <VendorEditor show={modelShow} onHide={onHide} actionType={actionType} formData={formData} handleSubmit={handleSubmit} />
            </Col>
        </Row>
    )
}

export default VendorPage;