import { useEffect, useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Cart, CurrencyRupee, Heart, Star, StarFill, StarHalf } from "react-bootstrap-icons";

export const ProductTiles = (props) => {
    const { theam, product, onAddCart, onLogin } = props
    const onCartClick = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser) {
            onAddCart(product, product.price);
        }
        else{
            onLogin()
        }
    }
    return (
        <Col md={3}>
            <Card style={{ width: '19rem' }} variant={theam}>
                <Card.Img variant="top" src={`data:image/jpeg;base64,${product?.productImage?.fileData}`} />
                <Card.Body>
                    <Card.Title>{product ? product.productTitle : ""} </Card.Title>
                    <Card.Text>
                        Code: {product ? product.productCode : ""}
                    </Card.Text>
                    <Card.Text>
                        {product ? <p>{product.categoryName} - {product.subCategoryName} - {product.tagTitle}</p> : ""}
                    </Card.Text>
                    <Card.Text>
                        Brand: {product ? product.vendorName : ""}
                    </Card.Text>
                    <Card.Text>
                        <StarFill /><StarFill /><StarFill /><StarHalf /><Star /> 
                    </Card.Text>
                    <Card.Text>
                        <b>Price: </b>{product.price}<CurrencyRupee />/ {product ? product.measuringUnitName : ""}
                        <a className="float-end" href={`/home/ProductDetails?productId=${product.productId}`}>View details</a>
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <a className='btn btn-primary' variant="primary" onClick={onCartClick}><Cart /> </a>
                    <a href={`/home/BuyProduct?productId=${product.productId}`} className='btn btn-primary float-end' variant="primary"> Buy now</a>
                </Card.Footer>
            </Card>
        </Col>
    )
}