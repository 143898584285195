import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardText, Col, Image, Navbar } from "react-bootstrap"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { GetDataService } from "../../Reducer/DataService/GetDataService";

export const CategoryTiles = (props) => {
    const { theam } = props
    const [categories, setCategories] = useState([]);
    const responsive = {
        superLargeDesktop: {

            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 10
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3
        }
    };
   
    useEffect(() => {
        getActiveCategories();
    }, []);

    const getActiveCategories = async () => {
        var response = await GetDataService("GetAllActiveCategories");
        setCategories(response);
    }
    const onTilesClick = (categoryId) => {

    }

    const getTiles = (row) => {
        return (
            <Navbar.Brand className="logo d-flex align-items-center">
                <Button variant={theam} onClick={onTilesClick(row.categoryId)}> {row.categoryName}</Button>
            </Navbar.Brand>
        )
    }
    return (
        <Navbar fixed="top" bg={theam} variant={theam} style={{ marginTop: 65, marginBottom: 60,maxHeight:40 }}>
            {categories ? categories.map((row) => getTiles(row)) : ""}
        </Navbar>
    )
}